import {Helmet} from 'react-helmet-async';
import {StaticRouterContext} from 'react-router';

interface Props {
  routerContext?: StaticRouterContext;
}

export function NotFound(props: Props) {
  if (props.routerContext) props.routerContext.statusCode = 404;

  return (
    <div className='container-xl'>
      <Helmet defer={false}>
        <title>Not Found | supersimple.dev</title>
        <meta
          name='description'
          content='Sorry, this page was not found.'
        />
      </Helmet>
      <div className='row'>
        <div className='col'>
          Page not found.
        </div>
      </div>
    </div>
  );
}
