import c from 'classnames';
import {Link, useLocation} from 'react-router-dom';

export function Navbar() {
  const location = useLocation();
  if (location.pathname === '/exercises/youtube') return null;

  return (
    <nav className='navbar navbar-expand-md navbar-light'>
      <div className='container-xl'>
        <Link to='/' className='navbar-brand'>
          <img src='/public/img/logo192.png' alt='logo' />
          supersimple.dev
        </Link>
        <button className='navbar-toggler' type='button'
          data-bs-toggle='collapse' data-bs-target='#navbarNavigation'
          aria-controls='navbarNavigation' aria-expanded='false'
          aria-label='Toggle navigation'>
          <span className='navbar-toggler-icon'></span>
        </button>

        <div className='collapse navbar-collapse' id='navbarNavigation'>
          <ul className='navbar-nav ms-md-2'>
            <li className='nav-item px-md-2'>
              <Link to='/courses' className={c('nav-link', {
                active: location.pathname.split('/')[1] === 'courses'
              })}>
                Learn
              </Link>
            </li>
            <li className='nav-item px-md-2'>
              <Link to='/certificates' className={c('nav-link', {
                active: location.pathname.split('/')[1] === 'certificates'
              })}>
                Certificates
              </Link>
            </li>
            {/* <li className='nav-item px-md-2'>
              <Link to='/tools' className={c('nav-link', {
                active: location.pathname.split('/')[1] === 'tools'
              })}>
                Tools
              </Link>
            </li> */}
            {/* <li className='nav-item px-md-2 mx-md-1 position-relative'>
              <Link to='/interview-guide' className={c('nav-link', {
                active: location.pathname.split('/')[1] === 'interview-guide'
              })}>
                Interview Guide
              </Link>
              <div className='new-badge-interview-guide d-none d-md-block'>
                new
              </div>
            </li> */}
          </ul>
          <ul className='navbar-nav ms-auto'>
            <li className='nav-item px-md-2'>
              <Link to='/donate' className={c('nav-link', {
                active: location.pathname.split('/')[1] === 'donate'
              })}>
                Donate
              </Link>
            </li>
            <li className='nav-item px-md-2'>
              <Link to='/about' className={c('nav-link', {
                active: location.pathname.split('/')[1] === 'about'
              })}>
                About
              </Link>
            </li>
            <li className='nav-item px-md-2'>
              <Link to='/contact' className={c('nav-link', {
                active: location.pathname.split('/')[1] === 'contact'
              })}>
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
