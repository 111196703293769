import {Helmet} from 'react-helmet-async';
import {CertificateCard} from './CertificateCard';

export function Certificates() {
  return (
    <div className='Certificates container-xl mt-5'>
      <Helmet defer={false}>
        <title>Certificates | supersimple.dev</title>
        <meta
          name='description'
          content='Certificates for SuperSimpleDev Courses.'
        />
      </Helmet>
      <div className='row'>
        <div className='col-md-6 col-lg-4'>
          <CertificateCard
            image={{
              src: '/public/img/courses/html-css-full-course-cover.png',
              alt: 'HTML CSS Full Course Logo'
            }}
            title='HTML & CSS Full Course'
            description='Learn how to build websites with HTML & CSS from a beginner to a professional level.'
            time='6 hour 31 mins'
            certificateLink='https://courses.supersimple.dev/courses/html-css'
            certificateInfoLink='/certificates/html-css'
          />
        </div>
        <div className='col-md-6 col-lg-4'>
          <CertificateCard
            image={{
              src: '/public/img/courses/javascript-full-course-cover.png',
              alt: 'JavaScript Full Course Logo'
            }}
            title='JavaScript Full Course'
            description='Learn how to build more complex, interactive websites with JavaScript from a beginner to a professional level.'
            time='22 hour 15 mins'
            certificateLink='https://courses.supersimple.dev/courses/javascript'
            certificateInfoLink='/certificates/javascript'
          />
        </div>
      </div>
    </div>
  );
}
