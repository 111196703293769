import { GradHatIcon } from '../../icons/bootstrap-1.4.0/GradHatIcon';
import { CheckIcon } from '../../icons/bootstrap-1.4.0/CheckIcon';
import { IndentLeftIcon } from '../../icons/bootstrap-1.4.0/IndentLeftIcon';
import { Link } from 'react-router-dom';

interface Props {
  image: {
    src: string;
    alt: string;
  };
  title: string;
  description: string;
  time: string;
  certificateLink: string;
  certificateInfoLink?: string;
}

export function CertificateCard({
  image, title, certificateLink, certificateInfoLink
}: Props) {
  return (
    <div className='CourseCardContainer'>
      <div className='CourseCard CertificateCard card pt-3 mb-1'>
        <div className='CourseCard__image px-3'>
          <img src={image.src} alt={image.alt} />
        </div>
        <div className='card-body'>
          <div className='CourseCard__title'>
            {title}<br/>
          </div>
          <div className='CertificateCard__subtitle'>
            (Premium Version With Certificate)
          </div>

          <div className='CertificateCard__info'>
            <div>
              <div className='CertificateCard__time'>
                <span className='CertificateCard__time-icon'>
                  <GradHatIcon />
                </span>
                Certificate of Completion
              </div>
              <div className='CertificateCard__time'>
                <span className='CertificateCard__time-icon'>
                  <CheckIcon />
                </span>
                Ad-free
              </div>
              <div className='CertificateCard__time'>
                <span className='CertificateCard__time-icon'>
                  <IndentLeftIcon />
                </span>
                Breakdown into small videos
              </div>
            </div>

            <div>
              <a href={certificateLink} className='btn btn-primary mt-3 me-3'>
                Enroll Now
              </a>
              {certificateInfoLink &&
                <Link to={certificateInfoLink} className='btn btn-outline-primary mt-3'>
                  Certificate Info
                </Link>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
