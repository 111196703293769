import * as bootstrap from 'bootstrap';
import React from 'react';
import ReactDOM from 'react-dom';
import {HelmetProvider} from 'react-helmet-async';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {createStore} from 'redux';
import App from './App';
import {rootReducer} from './redux';
// import reportWebVitals from './reportWebVitals';
import {detectBrowser} from './utils/detectBrowser';
import {setBootstrap} from './utils/globals';
import {isSSR} from './utils/ssr';

setBootstrap(bootstrap);
detectBrowser();
const store = createStore(rootReducer, window.initialStore,
  window['__REDUX_DEVTOOLS_EXTENSION__'] && window['__REDUX_DEVTOOLS_EXTENSION__']()
);

const render = isSSR() ? ReactDOM.hydrate : ReactDOM.render;
render(
  <React.StrictMode>
    <Provider store={store}>
      <HelmetProvider>
        <BrowserRouter>
          <App routerContext={{}} />
        </BrowserRouter>
      </HelmetProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
