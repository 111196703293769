import {throttle} from 'lodash';
import {useEffect, useState} from 'react';

type ScrollSpyElems = Element[];

export function useScrollSpy(linkSelector: string) {
  const [scrollSpyElems, setScrollSpyElems] = useState<ScrollSpyElems>([]);

  useEffect(() => {
    const result: ScrollSpyElems = [];

    document.querySelectorAll(linkSelector).forEach(link => {
      const contentElem = document.querySelector(
        (link as HTMLAnchorElement).hash
      );
      if (contentElem) result.push(contentElem);
    });

    setScrollSpyElems(result);
  }, [linkSelector]);

  const [activeLinkId, setActiveLinkId] = useState<string>('');

  useEffect(() => {
    const onScroll = throttle(() => {
      // Find the first element from the bottom where the current browser
      // scroll position is below the element (within a threshold).
      const threshold = 100;

      for (let i = scrollSpyElems.length - 1; i > 0; --i) {
        const elem = scrollSpyElems[i];
        if (elem.getBoundingClientRect().top < threshold) {
          setActiveLinkId(elem.id);
          return;
        }
      }

      setActiveLinkId(scrollSpyElems[0]?.id || '');
    }, 250, {trailing: true});

    window.addEventListener('scroll', onScroll);
    onScroll();

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [scrollSpyElems]);

  return activeLinkId;
}
