import {CodeBlock} from '../../../../components/CodeBlock';

export const Section5Code = <>
<CodeBlock
  language='html'
  fileName='todo.html'
  lineNumbers
  className='mt-0'
>{`<html>
  <head>
    <title>My Todo App</title>
  </head>
  <body>
    <button>Press me</button>
    <script>
      let todo1 = 'Get groceries';
      let todo2 = 'Wash car';
      let todo3 = 'Make dinner';

      let element = document.createElement('div');
      element.innerText = todo1;
      document.body.appendChild(element);

      element = document.createElement('div');
      element.innerText = todo2;
      document.body.appendChild(element);

      element = document.createElement('div');
      element.innerText = todo3;
      document.body.appendChild(element);
    </script>
  </body>
</html>`}</CodeBlock>
</>;
