import c from 'classnames';
import { useEffect, useRef } from 'react';
import {Helmet} from 'react-helmet-async';
import {YouTubeVideo} from '../../../components/YouTubeVideo';
import {useScrollSpy} from '../../../hooks/useScrollSpy';

export function GitHubPagesCourse2022() {
  const activeLink = useScrollSpy('.Course__nav a');

  const contentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (contentRef.current) Prism.highlightAllUnder(contentRef.current);
  }, []);

  return (
    <div className='Course container-xl'>
      <Helmet defer={false}>
        <title>GitHub Pages Course | supersimple.dev</title>
        <meta
          name='description'
          content='Learn how to put your website on the Internet with GitHub Pages.'
        />
      </Helmet>

      <div className='Course__nav'>
        <nav>
          <div className='nav-title'>GitHub Pages Course</div>
          <a className={c({
            active: activeLink === 'exercises-solutions'
          })} href='#exercises-solutions'>
            Exercises &amp; Solutions
          </a>
        </nav>
      </div>

      <main className='Course__content' ref={contentRef}>
        <section id='exercises-solutions'>
          <h2>
            Exercise &amp; Solutions
          </h2>

          <a href="https://github.com/SuperSimpleDev/github-pages-course-2022/tree/main/1-exercise-solutions"
            target="_blank"
            rel="noreferrer">
            View exercise solutions
          </a>
        </section>
      </main>
    </div>
  );
}
