import {CodeBlock} from '../../../../components/CodeBlock';

export const Section2Code = <>
<CodeBlock
  language='html'
  fileName='todo.html'
  lineNumbers
  className='mt-0'
>{`<button>Press me</button>
<script>
  alert('hello');
</script>`}</CodeBlock>
</>;
