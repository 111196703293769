import {Fragment, useEffect} from 'react';
import {Helmet} from 'react-helmet-async';
import {useDispatch} from 'react-redux';
import {StaticRouterContext} from 'react-router';
import {Dispatch} from 'redux';
import {Footer} from './components/Footer';
import {Navbar} from './components/Navbar';
import {Action} from './redux';
import {Router} from './Router';

interface Props {
  routerContext: StaticRouterContext;
}

function App({ routerContext }: Props) {
  const dispatch = useDispatch<Dispatch<Action>>();

  useEffect(() => {
    dispatch({ type: 'resetInitialState' });
  }, [dispatch]);

  return (
    <Fragment>
      <Helmet defer={false}>
        <title>supersimple.dev | Learn Software Engineering</title>
      </Helmet>
      <Navbar />
      <Router routerContext={routerContext} />
      <Footer />
    </Fragment>
  );
}

export default App;
