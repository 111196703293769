import {Helmet} from 'react-helmet-async';

export function GitGithub20211() {
  return (
    <div className='container-xl mt-4'>
      <Helmet defer={false}>
        <title>Git and GitHub Complete Course | supersimple.dev</title>
        <meta
          name='description'
          content='Comprehensive course on Git and GitHub. We learn from 0 experience to professional.'
        />
      </Helmet>

      <div>
        <h1 className='h2 fw-bold mb-3'>
          Git and GitHub Complete Course
        </h1>

        <h2 className='h4 fw-bold mt-5'>
          Cheatsheet
        </h2>
        <a href='https://supersimpledev.github.io/references/git-github-reference.pdf'
          target='_blank' rel='noreferrer'>
          Cheatsheet
        </a>
      </div>
    </div>
  );
}
