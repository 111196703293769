import {CodeBlock} from '../../../../components/CodeBlock';

export const Section4Code = <>
<CodeBlock
  language='html'
  fileName='todo.html'
  lineNumbers
  className='mt-0'
>{`<html>
  <head>
    <title>My Todo App</title>
  </head>
  <body>
    <button>Press me</button>
    <div>Get groceries</div>
    <div>Wash car</div>
    <div>Make dinner</div>
    <script>
      let todo1 = 'Get groceries';
      let todo2 = 'Wash car';
      let todo3 = 'Make dinner';
    </script>
  </body>
</html>`}</CodeBlock>
</>;
