import {CodeBlock} from '../../../../components/CodeBlock';

export const Section12Exercises = <>
  <div className='exercise'>
    <p>
      <strong>Exercise 1</strong><br />
      I accidentally copied this question from one of the earlier exercises. Skip this one!
    </p>

    <p>Remember that <code>{`array.filter(function (item) { ... });`}</code> will loop through the array, keep items if the inner function returns true, and remove items if the inner function returns false.</p>
  </div>

  <div className='exercise'>
    <p>
      <strong>Exercise 2</strong><br />
      Write a function <code>aboveFreezing</code> that takes an array of numbers <code>temps</code> and returns an array with only temperatures that are above the freezing point of water. Temperature can be in Celsius or Fahrenheit, you decide.
    </p>
    <CodeBlock
      revealId='s12e1'
      language='javascript'
    >
{`function aboveFreezing(temps) {
  // Assume we're using Fahrenheit
  const tempsAboveFreezing = temps.filter(function (temp) {
    return temp > 32;
  });

  return tempsAboveFreezing;
}
aboveFreezing([40, 67, 8, 29, 100, -3, 0]);`}
    </CodeBlock>
  </div>

  <div className='exercise'>
    <p>
      <strong>Exercise 3</strong><br />
      Write a function <code>removeRed</code> that takes an array of objects <code>foodArray</code> with the format: <code>{`[{ name: 'Apple', color: 'red' }, { name: 'Egg', color: 'white' }]`}</code> and removes all red-colored foods from the array.
    </p>
    <CodeBlock
      revealId='s12e2'
      language='javascript'
    >
{`function removeRed(foodArray) {
  // Instead of saving the filtered array in a variable,
  // we can return it in the same line.
  return foodArray.filter(function (food) {
    return food.color !== 'red';
  });
}
removeRed([{ name: 'Apple', color: 'red' }, { name: 'Egg', color: 'white' }]);`}
    </CodeBlock>
  </div>
</>;
