import c from 'classnames';
import React, {ReactNode} from 'react';
import {usePersistAccordion} from './usePersistentAccordion';

interface Props {
  id: string;
  className?: string;
  items: {
    heading: ReactNode;
    body: ReactNode;
  }[];
}

export function Accordion({
  id, className, items
}: Props) {
  const expandedSections = usePersistAccordion(id);

  return (
    <div className={c('accordion', className)} id={id}>
      {items.map(({heading, body}, index) => {
        const bodyId = `${id}-collapse-${index}`;
        const isExpanded = expandedSections[bodyId];

        return (
          <div className='accordion-item' key={index}>
            <h3 className='accordion-header' id={`${id}-heading-${index}`}>
              <button
                className={c('accordion-button', {
                  collapsed: !isExpanded
                })}
                type='button'
                data-bs-toggle='collapse'
                data-bs-target={`#${id}-collapse-${index}`}
                aria-expanded={isExpanded ? 'true' : 'false'}
                aria-controls={`${id}-collapse-${index}`}>
                {heading}
              </button>
            </h3>

            <div
              id={bodyId}
              className={c('accordion-collapse collapse', {
                show: isExpanded
              })}
              aria-labelledby={`${id}-heading-${index}`}>
              <div className='accordion-body'>
                {body}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
