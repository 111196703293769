import {format} from 'date-fns';
import {CodeBlock} from '../../../../components/CodeBlock';

export const Section1Exercises = <>
  <div className='exercise'>
    <p>
      <strong>Exercise 1</strong><br />
      We learned <code>alert('hello');</code> to create a popup with the text <code>hello</code>. Now create a popup with your name in it.
    </p>
    <CodeBlock
      revealId='s1e1'
      language='javascript'
    >{`alert('Simon');`}</CodeBlock>
  </div>

  <div className='exercise'>
    <p>
      <strong>Exercise 2</strong><br />
      Display today’s date in the Console in Chrome.
    </p>
    <CodeBlock
      revealId='s1e2'
      language='javascript'
    >{`console.log('${format(new Date(), 'EEEE, MMMM d, yyyy')}');`}</CodeBlock>
  </div>

  <div className='exercise'>
    <p>
      <strong>Exercise 3</strong><br />
      Overwrite the webpage using <code>document.body.innerHTML</code> so that the page just displays your name.
    </p>
    <CodeBlock
      revealId='s1e3'
      language='javascript'
    >{`document.body.innerHTML = 'Simon';`}</CodeBlock>
  </div>

  <div className='exercise'>
    <p>
      <strong>Exercise 4</strong><br />
      Overwrite the webpage to be empty.
    </p>
    <CodeBlock
      revealId='s1e4'
      language='javascript'
    >{`document.body.innerHTML = '';`}</CodeBlock>
  </div>
</>;
