import {useRouteMatch, Redirect, Route, StaticRouterContext} from 'react-router';
import {SwitchWith404} from '../../../components/SwitchWith404';
import {NestedLayoutsTechnique} from './NestedLayoutsTechnique';

interface Props {
  routerContext: StaticRouterContext;
}

export function CSSReferenceRouter({ routerContext }: Props) {
  const match = useRouteMatch();

  return (
    <SwitchWith404 routerContext={routerContext}>
      <Route path={`${match.path}/nested-layouts-technique`}>
        <NestedLayoutsTechnique />
      </Route>
      <Route path={`${match.path}/nested-layout-technique`}>
        <Redirect to={`${match.path}/nested-layouts-technique`} />
      </Route>
    </SwitchWith404>
  );
}
