import React, {ReactNode} from 'react';

interface Props {
  to: string;
  children: ReactNode
}

export function ScrollLink({
  to, children
}: Props) {
  const scrollToLink = () => {
    const elem = document.querySelector(`[data-scroll="${to}"]`);
    if (elem) elem.scrollIntoView();
  };
  return <span className='link-primary' onClick={scrollToLink}>{children}</span>
}
