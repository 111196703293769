import {Helmet} from 'react-helmet-async';

export function About() {
  return (
    <div className='Contact container-xl mt-5'>
      <Helmet defer={false}>
        <title>Contact | supersimple.dev</title>
        <meta
          name='description'
          content="More about SuperSimpleDev"
        />
      </Helmet>
      <div className='row'>
        <div className='col-md-6'>
          <h1 className='h2 fw-bold mb-4'>
            About Us
          </h1>
          <p className='mb-4'>
            Hello! We are <strong><u>Simon</u></strong> and <strong><u>Suming</u></strong>, and together, we are <strong><u>Su</u></strong>per<strong><u>Si</u></strong>mpleDev. We started our YouTube channel with the goal of providing high-quality educational videos about software engineering.
          </p>
        </div>
        <div className='col-md-6'>
          <img className='about-us-pic'
            src='/public/img/about-us-pic.jpg'
            alt='a picture of SuperSimpleDev' />
        </div>
      </div>
    </div>
  );
}
