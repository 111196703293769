import {CodeBlock} from '../../../../components/CodeBlock';

export const Section10Exercises = <>
  <div className='exercise'>
    <p>
      <strong>Exercise 1</strong><br />
      Write a function <code>cartTotal</code> that takes an array of objects <code>cartArray</code> where each object contains a name, price, and quantity. console.log the total price of the items in the cart. For example:<br/>
    </p>
    <CodeBlock
      language='javascript'
    >
{`cartTotal([
  { name: 'Apple', price: 4, quantity: 2 },
  { name: 'Orange', price: 3, quantity: 3 }
]);`}
    </CodeBlock>
    Will console.log(17);
    <CodeBlock
      revealId='s10e1'
      language='javascript'
      fileName='answer.js'
    >
{`function cartTotal(cartArray) {
  let total = 0;

  cartArray.forEach(function (item) {
    total = total + item.price * item.quantity;
  });

  console.log(total);
}
cartTotal([
  { name: 'Apple', price: 4, quantity: 2 },
  { name: 'Orange', price: 3, quantity: 3 }
]);`}
    </CodeBlock>
  </div>

  <div className='exercise'>
    <p>
      <strong>Exercise 2</strong><br />
      Create a <code>{`<div id="receipt"></div>`}</code>. Write a function <code>displayReceipt</code> that takes the same array of objects as exercise 1 and display a receipt using these steps:
    </p>
    <ol>
      <li>Reset <code>{`<div id="receipt">`}</code> to be empty using <code>.innerHTML</code></li>
      <li>For each object in the array, add a line to the receipt in this format: <code>{`<div>Apple $4 * 2 = $8</div>`}</code></li>
      <li>At the bottom, display the total: <code>{`<div>Cart total = $17</div>`}</code></li>
    </ol>
    <video src='/public/courses/jsbeginner20201/s10e2.mov' muted controls style={{width: 430}}
      className='my-2'>
      <source src='/public/courses/jsbeginner20201/s10e2.mov' type='video/mp4' />
      Your browser does not support the video tag.
    </video>
    <CodeBlock
      revealId='s10e2'
      language='html'
      fileName='solution.html'
    >
{`<div id="receipt"></div>
<script>
  const receipt = document.getElementById('receipt');

  function displayReceipt(cartArray) {
    receipt.innerHTML = '';

    cartArray.forEach(function (item) {
      const receiptLine = document.createElement('div');
      receiptLine.innerText = item.name + ' `}${`' + item.price + ' * ' + item.quantity;
      receipt.appendChild(receiptLine);
    });

    // You can also calculate the total using just the loop above, but I like
    // to separate them out so each loop does one thing.
    let cartTotal = 0;
    cartArray.forEach(function (item) {
      cartTotal = cartTotal + item.price * item.quantity;
    });

    const totalLine = document.createElement('div');
    totalLine.innerText = 'Cart total = `}${`' + cartTotal;
    receipt.appendChild(totalLine);
  }

  displayReceipt([
    { name: 'Apple', price: 4, quantity: 2 },
    { name: 'Orange', price: 3, quantity: 3 }
  ]);
</script>`}
    </CodeBlock>
  </div>
</>;
