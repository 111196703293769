import {CodeBlock} from '../../../../components/CodeBlock';
import {ScrollLink} from '../../../../components/ScrollLink';

export const Section14Exercises = <>
  <div className='exercise'>
    <p>
      <strong>Exercise 1</strong><br />
      Note: make a copy of the code in progress and add to it. The code in progress can be found <ScrollLink to='code-in-progress'>here</ScrollLink>
    </p>
    <p>We’ll add a checkbox to each todo to show that the todo is done.</p>

    <ol>
      <li>
        When rendering each todo, create an input element with <code>.type = 'checkbox';</code>. Note: using <code>.innerText</code> wipes out the contents of the todo so to insert the checkbox before the title, use <code>element.prepend(checkbox)</code>
      </li>
      <li>Unlike textboxes, use <code>.onChange</code> instead of <code>.onClick</code> to handle clicks on the checkbox. Use <code>checkbox.checked</code> instead of <code>checkbox.value</code> to get whether or not the checkbox is checked (true if checked, false if unchecked).</li>
      <li>When the checkbox is clicked, find the todo associated with the checkbox and set a property <code>todo.isDone = checkbox.checked;</code>, then rerender. Hint: when creating the checkbox, save the todo id to the checkbox using <code>checkbox.dataset.todoId = todo.id;</code> You can later use <code>checkbox.dataset</code> to retrieve the id that you saved. (To follow MVC, put the code for modifying the <code>.isDone</code> property in a separate function in the Model section.)</li>
      <li>Add some code to the render function: <code>if (todo.isDone === true)</code>, set the <code>.checked</code> property on the checkbox element to true. Otherwise, set it to false.</li>
    </ol>
    <video src='/public/courses/jsbeginner20201/s14e1.mov' muted controls style={{width: 480}}
      className='my-2'>
      <source src='/public/courses/jsbeginner20201/s14e1.mov' type='video/mp4' />
      Your browser does not support the video tag.
    </video>
    <CodeBlock
      revealId='s14e1'
      language='html'
      fileName='solution.html'
      lineNumbers
      highlightLines='52-58, 80-88, 99-108'
    >
{`<html>
  <head>
    <title>My Todo App</title>
  </head>
  <body>
    <input id="todo-title" type="text" />
    <input id="date-picker" type="date" />
    <button onclick="addTodo()">Add Todo</button>

    <div id="todo-list"></div>

    <script>
      // Model
      let todos = [{
        title: 'Get groceries',
        dueDate: '2021-10-04',
        id: 'id1'
      }, {
        title: 'Wash car',
        dueDate: '2021-02-03',
        id: 'id2'
      }, {
        title: 'Make dinner',
        dueDate: '2021-03-04',
        id: 'id3'
      }];

      // Creates a todo
      function createTodo(title, dueDate) {
        const id = '' + new Date().getTime();

        todos.push({
          title: title,
          dueDate: dueDate,
          id: id
        });
      }

      // Deletes a todo
      function removeTodo(idToDelete) {
        todos = todos.filter(function (todo) {
          // If the id of this todo matches idToDelete, return false
          // For everything else, return true
          if (todo.id === idToDelete) {
            return false;
          } else {
            return true;
          }
        });
      }

      function toggleTodo(todoId, checked) {
        todos.forEach(function (todo) {
          if (todo.id === todoId) {
            todo.isDone = checked;
          }
        });
      }

      // Controller
      function addTodo() {
        const textbox = document.getElementById('todo-title');
        const title = textbox.value;

        const datePicker = document.getElementById('date-picker');
        const dueDate = datePicker.value;

        createTodo(title, dueDate);
        render();
      }

      function deleteTodo(event) {
        const deleteButton = event.target;
        const idToDelete = deleteButton.id;

        removeTodo(idToDelete);
        render();
      }

      function checkTodo(event) {
        const checkbox = event.target;

        const todoId = checkbox.dataset.todoId;
        const checked = checkbox.checked;

        toggleTodo(todoId, checked);
        render();
      }

      // View
      function render() {
        // reset our list
        document.getElementById('todo-list').innerHTML = '';

        todos.forEach(function (todo) {
          const element = document.createElement('div');
          element.innerText = todo.title + ' ' + todo.dueDate;

          const checkbox = document.createElement('input');
          checkbox.type = 'checkbox';
          checkbox.onchange = checkTodo;
          checkbox.dataset.todoId = todo.id;
          if (todo.isDone === true) {
            checkbox.checked = true;
          } else {
            checkbox.checked = false;
          }
          element.prepend(checkbox);

          const deleteButton = document.createElement('button');
          deleteButton.innerText = 'Delete';
          deleteButton.style = 'margin-left: 12px';
          deleteButton.onclick = deleteTodo;
          deleteButton.id = todo.id;
          element.appendChild(deleteButton);

          const todoList = document.getElementById('todo-list');
          todoList.appendChild(element);
        });
      }

      render();
    </script>
  </body>
</html>`}
    </CodeBlock>
  </div>
</>;
