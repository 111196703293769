import {useEffect, useState} from 'react';

export function usePersistAccordion(id: string) {
  const [expandedSections, setExpandedSections] = useState({});
  const sessionKey = `${id}-accordion-expanded`;

  useEffect(() => {
    setExpandedSections(
      JSON.parse(
        sessionStorage.getItem(sessionKey) ||
        `{"${id}-collapse-0": true}`
      )
    );
  }, [id, sessionKey]);

  useEffect(() => {
    const accordion = document.getElementById(id);
    if (!accordion) return;

    const onShow = (e: any) => {
      const newExpandedSections = {
        ...expandedSections,
        [e.target.id]: true
      };

      setExpandedSections(newExpandedSections);
      sessionStorage.setItem(sessionKey, JSON.stringify(newExpandedSections));
    };
    accordion.addEventListener('show.bs.collapse', onShow);

    const onHide = (e: any) => {
      const newExpandedSections = {...expandedSections};
      delete newExpandedSections[e.target.id];

      setExpandedSections(newExpandedSections);
      sessionStorage.setItem(sessionKey, JSON.stringify(newExpandedSections));
    };
    accordion.addEventListener('hide.bs.collapse', onHide);

    return () => {
      accordion.removeEventListener('show.bs.collapse', onShow);
      accordion.removeEventListener('hide.bs.collapse', onHide);
    };
  }, [id, expandedSections, sessionKey]);

  return expandedSections;
}
