import {CodeBlock} from '../../../../components/CodeBlock';

export const Section13Exercises = <>
  <div className='exercise'>
    <p>
      <strong>Exercise 1</strong><br />
      Write a function named <code>max</code> that takes an array of numbers and returns the largest number in the array.<br/>
      Example: <code>max([1, 5, -2, 4, 3, 5, 0]);</code> will return 5
    </p>
    <CodeBlock
      revealId='s13e1'
      language='javascript'
    >
{`function max(numArray) {
  let max = -Infinity;

  numArray.forEach(function (num) {
    // You can have an if statement without the else
    if (num > max) {
      max = num;
    }
  });

  return max;
}
max([1, 5, -2, 4, 3, 5, 0]);`}
    </CodeBlock>
  </div>

  <div className='exercise'>
    <p>
      <strong>Exercise 2</strong><br />
      Similarly, write a function <code>min</code> that returns the smallest number in the array.
    </p>
    <CodeBlock
      revealId='s13e2'
      language='javascript'
    >
{`function min(numArray) {
  let min = Infinity;

  numArray.forEach(function (num) {
    if (num < min) {
      min = num;
    }
  });

  return min;
}
min([1, 5, -2, 4, 3, 5, 0]);`}
    </CodeBlock>
  </div>

  <div className='exercise'>
    <p>
      <strong>Exercise 3</strong><br />
      Write a function that returns the smallest number that’s greater or equal to 0.
    </p>
    <CodeBlock
      revealId='s13e3'
      language='javascript'
    >
{`function minNonNegative(numArray) {
  let min = Infinity;

  numArray.forEach(function (num) {
    if (num < 0) {
      return;
    } else if (num < min) {
      min = num
    }
  });

  return min;
}
minNonNegative([1, 5, -2, 4, 3, 5, 0]);`}
    </CodeBlock>
  </div>

  <div className='exercise'>
    <p>
      <strong>Exercise 4</strong><br />
      Write a function <code>pickApples</code> that takes an array of strings representing fruits and returns the array with the first 2 occurrences of "apple" removed.
    </p>
    <p>
      Example: <code>pickApples(['cherry', 'apple', 'orange', 'apple', 'banana', 'apple']);</code>
      should return <code>['cherry', 'orange', 'banana', 'apple']</code>
    </p>
    <CodeBlock
      revealId='s13e4'
      language='javascript'
    >
{`function pickApples(fruitArray) {
  let applesPicked = 0;

  const filteredArray = fruitArray.filter(function (fruit) {
    if (applesPicked >= 2) {
      // Remember, returning true keeps this value in the array.
      return true;
    } else if (fruit === 'apple') {
      applesPicked = applesPicked + 1;
      return false;
    } else {
      return true;
    }
  });

  return filteredArray;
}
pickApples(['cherry', 'apple', 'orange', 'apple', 'banana', 'apple']);`}
    </CodeBlock>
  </div>

  <div className='exercise'>
    <p>
      <strong>Exercise 5</strong><br />
      Similarly, write a function <code>pickFruits</code> that removes 2 apples and 1 orange from the array.
    </p>
    <CodeBlock
      revealId='s13e5'
      language='javascript'
    >
{`function pickFruits(fruitArray) {
  let applesPicked = 0;
  let orangesPicked = 0;

  const filteredArray = fruitArray.filter(function (fruit) {
    if (fruit === 'apple') {
      if (applesPicked >= 2) {
        return true;
      } else {
        applesPicked = applesPicked + 1;
        return false;
      }
    } else if (fruit === 'orange') {
      if (orangesPicked >= 1) {
        return true;
      } else {
        orangesPicked = orangesPicked + 1;
        return false;
      }
    } else {
      return true;
    }
  });

  return filteredArray;
}
pickFruits(['cherry', 'apple', 'orange', 'apple', 'banana', 'apple']);`}
    </CodeBlock>
  </div>

  <div className='exercise'>
    <p>
      <strong>Exercise 6</strong><br />
      Write a function <code>pickLastApples</code> similar to above that removes the last 2 apples from the array. (Use Google to get the code for reversing an array.)
    </p>
    <CodeBlock
      revealId='s13e6'
      language='javascript'
    >
{`function pickLastApples(fruitArray) {
  let applesPicked = 0;

  const reversedArray = fruitArray.reverse();

  const filteredArray = reversedArray.filter(function (fruit) {
    if (applesPicked >= 2) {
      return true;
    } else if (fruit === 'apple') {
      applesPicked = applesPicked + 1;
      return false;
    } else {
      return true;
    }
  });

  // Remember to reverse the array back.
  return filteredArray.reverse();
}
pickLastApples(['cherry', 'apple', 'orange', 'apple', 'banana', 'apple']);`}
    </CodeBlock>
  </div>
</>;
