import {useRouteMatch, Redirect, Route, StaticRouterContext} from 'react-router';
import {SwitchWith404} from '../../../components/SwitchWith404';
import { DNSRecords } from './DNSRecords';
import { GitHubPages } from './GitHubPages';

interface Props {
  routerContext: StaticRouterContext;
}

export function InternetReferenceRouter({ routerContext }: Props) {
  const match = useRouteMatch();

  return (
    <SwitchWith404 routerContext={routerContext}>
      <Route path={`${match.path}/github-pages`}>
        <GitHubPages />
      </Route>
      <Route path={`${match.path}/dns-records`}>
        <DNSRecords />
      </Route>
      <Route path={`${match.path}/dns-record`}>
        <Redirect to={`${match.path}/dns-records`} />
      </Route>
    </SwitchWith404>
  );
}
