export function GitHubPages() {
  return (
    <div className='GitHubPagesReference container-xl'>
      <div className='row'>
        <div className='col-6 offset-3'>
          <h1 className='h3'>GitHub Pages Reference</h1>
          <p className='mb-3'>
            <a href="https://supersimpledev.github.io/references/github-pages-reference.pdf" target="_blank" rel="noreferrer">
              GitHub Pages Reference
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
