import {ReduxState} from '../../shared/interfaces/redux';

export type Action = {
  type: 'resetInitialState'
};

export function rootReducer(state: ReduxState = {}, action: Action) {
  if (action.type === 'resetInitialState') {
    return {
      ...state,
      initialState: {}
    };
  }
  return state;
}
