import {CodeBlock} from '../../../../components/CodeBlock';

export const Section3Code = <>
<CodeBlock
  language='html'
  fileName='todo.html'
  lineNumbers
  className='mt-0'
>{`<button>Press me</button>
<script>
  let todo1 = 'Get groceries';
  let todo2 = 'Wash car';
  let todo3 = 'Make dinner';
</script>`}</CodeBlock>
</>;
