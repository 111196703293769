import {StaticRouterContext, Route, Redirect} from 'react-router';
import {SwitchWith404} from './components/SwitchWith404';
import {Contact} from './routes/Contact';
import {CourseRouter} from './routes/courses/CourseRouter';
import {ButtonExercises} from './routes/exercises/html-css-course/Buttons';
import {TextExercises} from './routes/exercises/html-css-course/Text';
import {YouTubeExercise} from './routes/exercises/html-css-course/YouTube';
import {Home} from './routes/Home';
import {HTMLReferenceRouter} from './routes/references/html/HTMLReferenceRouter';
import {CSSReferenceRouter} from './routes/references/css/CSSReferenceRouter';
import { InternetReferenceRouter } from './routes/references/internet/InternetReferenceRouter';
import { Donate } from './routes/Donate';
import { ThankYouDonation } from './routes/thankYou/ThankYouDonation';
import { About } from './routes/About';
import { Certificates } from './routes/certificates/Certificates';
import { Tools } from './routes/Tools';
import { CertificatesJavaScript } from './routes/certificates/CertificatesJavaScript';
import { CertificatesHTMLCSS } from './routes/certificates/CertificatesHTMLCSS';

interface Props {
  routerContext: StaticRouterContext;
}

export function Router({ routerContext }: Props) {
  return (
    <SwitchWith404 routerContext={routerContext}>
      <Route exact path='/'>
        <Home />
      </Route>
      <Route path='/courses'>
        <CourseRouter routerContext={routerContext} />
      </Route>
      <Route path='/certificates/html-css'>
        <CertificatesHTMLCSS />
      </Route>
      <Route path='/certificates/javascript'>
        <CertificatesJavaScript />
      </Route>
      <Route path='/certificates'>
        <Certificates />
      </Route>
      <Route path='/html'>
        <HTMLReferenceRouter routerContext={routerContext} />
      </Route>
      <Route path='/css'>
        <CSSReferenceRouter routerContext={routerContext} />
      </Route>
      <Route path='/internet'>
        <InternetReferenceRouter routerContext={routerContext} />
      </Route>
      <Route path='/donate'>
        <Donate />
      </Route>
      <Route path='/thank-you/donation'>
        <ThankYouDonation />
      </Route>
      <Route path='/contact'>
        <Contact />
      </Route>
      {/* <Route path='/tools'>
        <Tools />
      </Route> */}
      <Route path='/about'>
        <About />
      </Route>
      <Route path='/feedback'>
        <Redirect to='/contact' />
      </Route>
      <Route path='/exercises/buttons'>
        <ButtonExercises />
      </Route>
      <Route path='/exercises/text'>
        <TextExercises />
      </Route>
      <Route path='/exercises/youtube'>
        <YouTubeExercise />
      </Route>
    </SwitchWith404>
  );
}
