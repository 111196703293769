/* eslint 'jsx-a11y/alt-text': 'off' */
export function YouTubeExercise() {
  return (
    <div className='Exercises-YouTube'>
      <header className='header'>
        <div className='left-section'>
          <img className='hamburger-menu' src='/public/img/exercises/youtube/icons/hamburger-menu.svg' />
          <img className='youtube-logo' src='/public/img/exercises/youtube/icons/youtube-logo.svg' />
        </div>

        <div className='middle-section'>
          <input className='search-bar' type='text' placeholder='Search' />
          <button className='search-button tooltip-container'>
            <img src='/public/img/exercises/youtube/icons/search.svg' />
            <div className='tooltip'>Search</div>
          </button>
          <button className='voice-search tooltip-container'>
            <img src='/public/img/exercises/youtube/icons/voice-search-icon.svg' />
            <div className='tooltip'>Search with your voice</div>
          </button>
        </div>

        <div className='right-section'>
          <div className='tooltip-container'>
            <img className='icon' src='/public/img/exercises/youtube/icons/upload.svg' />
            <div className='tooltip'>Create</div>
          </div>
          <div className='tooltip-container'>
            <img className='icon' src='/public/img/exercises/youtube/icons/youtube-apps.svg' />
            <div className='tooltip'>YouTube apps</div>
          </div>
          <div className='tooltip-container'>
            <img className='icon' src='/public/img/exercises/youtube/icons/notifications.svg' />
            <div className='notification-count'>3</div>
            <div className='tooltip'>Notifications</div>
          </div>
          <img className='my-channel' src='/public/img/exercises/youtube/channel-profile-pics/my-channel.jpeg' />
        </div>
      </header>

      <nav className='sidebar'>
        <div className='sidebar-link'>
          <img src='/public/img/exercises/youtube/icons/home.svg' />
          <div>Home</div>
        </div>
        <div className='sidebar-link'>
          <img src='/public/img/exercises/youtube/icons/explore.svg' />
          <div>Explore</div>
        </div>
        <div className='sidebar-link'>
          <img src='/public/img/exercises/youtube/icons/subscriptions.svg' />
          <div>Subscriptions</div>
        </div>
        <div className='sidebar-link'>
          <img src='/public/img/exercises/youtube/icons/originals.svg' />
          <div>Originals</div>
        </div>
        <div className='sidebar-link'>
          <img src='/public/img/exercises/youtube/icons/youtube-music.svg' />
          <div>YouTube Music</div>
        </div>
        <div className='sidebar-link'>
          <img src='/public/img/exercises/youtube/icons/library.svg' />
          <div>Library</div>
        </div>
      </nav>

      <main>
        <section className='video-grid'>
          <div className='video-card'>
            <div className='thumbnail-container'>
              <a href='https://www.youtube.com/watch?v=n2RNcPRtAiY'>
                <img className='thumbnail' src='/public/img/exercises/youtube/thumbnails/thumbnail-1.webp' />
              </a>
              <div className='video-time'>14:20</div>
            </div>
            <div className='video-info-grid'>
              <div className='channel-profile-pic'>
                <a href='https://www.youtube.com/c/mkbhd'>
                  <img src='/public/img/exercises/youtube/channel-profile-pics/channel-1.jpeg' />
                </a>
              </div>
              <div className='video-info'>
                <p className='video-title'>
                  <a className='video-link' href='https://www.youtube.com/watch?v=n2RNcPRtAiY'>
                    Talking Tech and AI with Google CEO Sundar Pichai!
                  </a>
                </p>
                <p className='channel-name'>
                  <a className='channel-link' href='https://www.youtube.com/c/mkbhd'>
                    Marques Brownlee
                  </a>
                </p>
                <p className='video-stats'>
                  3.4M views &#183; 6 months ago
                </p>
              </div>
            </div>
          </div>

          <div className='video-card'>
            <div className='thumbnail-container'>
              <a href='https://www.youtube.com/watch?v=mP0RAo9SKZk'>
                <img className='thumbnail' src='/public/img/exercises/youtube/thumbnails/thumbnail-2.webp' />
              </a>
              <div className='video-time'>8:22</div>
            </div>
            <div className='video-info-grid'>
              <div className='channel-profile-pic'>
                <a href='https://www.youtube.com/c/markiplier'>
                  <img src='/public/img/exercises/youtube/channel-profile-pics/channel-2.jpeg' />
                </a>
              </div>
              <div className='video-info'>
                <p className='video-title'>
                  <a className='video-link' href='https://www.youtube.com/watch?v=mP0RAo9SKZk'>
                    Try Not To Laugh Challenge #9
                  </a>
                </p>
                <p className='channel-name'>
                  <a className='channel-link' href='https://www.youtube.com/c/markiplier'>
                    Markiplier
                  </a>
                </p>
                <p className='video-stats'>
                  19M views &#183; 4 years ago
                </p>
              </div>
            </div>
          </div>

          <div className='video-card'>
            <div className='thumbnail-container'>
              <a href='https://www.youtube.com/watch?v=FgjPQQeTh1w'>
                <img className='thumbnail' src='/public/img/exercises/youtube/thumbnails/thumbnail-3.webp' />
              </a>
              <div className='video-time'>9:13</div>
            </div>
            <div className='video-info-grid'>
              <div className='channel-profile-pic'>
                <a href='https://www.youtube.com/user/SSSniperWolf'>
                  <img src='/public/img/exercises/youtube/channel-profile-pics/channel-3.jpeg' />
                </a>
              </div>
              <div className='video-info'>
                <p className='video-title'>
                  <a className='video-link' href='https://www.youtube.com/watch?v=FgjPQQeTh1w'>
                    Crazy Tik Toks Taken Moments Before DISASTER
                  </a>
                </p>
                <p className='channel-name'>
                  <a className='channel-link' href='https://www.youtube.com/user/SSSniperWolf'>
                    SSSniperWolf
                  </a>
                </p>
                <p className='video-stats'>
                  12M views &#183; 1 year ago
                </p>
              </div>
            </div>
          </div>

          <div className='video-card'>
            <div className='thumbnail-container'>
              <a href='https://www.youtube.com/watch?v=094y1Z2wpJg'>
                <img className='thumbnail' src='/public/img/exercises/youtube/thumbnails/thumbnail-4.webp' />
              </a>
              <div className='video-time'>22:09</div>
            </div>
            <div className='video-info-grid'>
              <div className='channel-profile-pic'>
                <a href='https://www.youtube.com/c/veritasium'>
                  <img src='/public/img/exercises/youtube/channel-profile-pics/channel-4.jpeg' />
                </a>
              </div>
              <div className='video-info'>
                <p className='video-title'>
                  <a className='video-link' href='https://www.youtube.com/watch?v=094y1Z2wpJg'>
                    The Simplest Math Problem No One Can Solve - Collatz Conjecture
                  </a>
                </p>
                <p className='channel-name'>
                  <a className='channel-link' href='https://www.youtube.com/c/veritasium'>
                    Veritasium
                  </a>
                </p>
                <p className='video-stats'>
                  18M views &#183; 4 months ago
                </p>
              </div>
            </div>
          </div>

          <div className='video-card'>
            <div className='thumbnail-container'>
              <a href='https://www.youtube.com/watch?v=86CQq3pKSUw'>
                <img className='thumbnail' src='/public/img/exercises/youtube/thumbnails/thumbnail-5.webp' />
              </a>
              <div className='video-time'>11:17</div>
            </div>
            <div className='video-info-grid'>
              <div className='channel-profile-pic'>
                <a href='https://www.youtube.com/c/CSDojo'>
                  <img src='/public/img/exercises/youtube/channel-profile-pics/channel-5.jpeg' />
                </a>
              </div>
              <div className='video-info'>
                <p className='video-title'>
                  <a className='video-link' href='https://www.youtube.com/watch?v=86CQq3pKSUw'>
                    Kadane's Algorithm to Maximum Sum Subarray Problem
                  </a>
                </p>
                <p className='channel-name'>
                  <a className='channel-link' href='https://www.youtube.com/c/CSDojo'>
                    CS Dojo
                  </a>
                </p>
                <p className='video-stats'>
                  519K views &#183; 5 years ago
                </p>
              </div>
            </div>
          </div>

          <div className='video-card'>
            <div className='thumbnail-container'>
              <a href='https://www.youtube.com/watch?v=yXWw0_UfSFg'>
                <img className='thumbnail' src='/public/img/exercises/youtube/thumbnails/thumbnail-6.webp' />
              </a>
              <div className='video-time'>19:59</div>
            </div>
            <div className='video-info-grid'>
              <div className='channel-profile-pic'>
                <a href='https://www.youtube.com/channel/UCX6OQ3DkcsbYNE6H8uQQuVA'>
                  <img src='/public/img/exercises/youtube/channel-profile-pics/channel-6.jpeg' />
                </a>
              </div>
              <div className='video-info'>
                <p className='video-title'>
                  <a className='video-link' href='https://www.youtube.com/watch?v=yXWw0_UfSFg'>
                    Anything You Can Fit In The Circle I’ll Pay For
                  </a>
                </p>
                <p className='channel-name'>
                  <a className='channel-link' href='https://www.youtube.com/channel/UCX6OQ3DkcsbYNE6H8uQQuVA'>
                    MrBeast
                  </a>
                </p>
                <p className='video-stats'>
                  141M views &#183; 1 year ago
                </p>
              </div>
            </div>
          </div>

          <div className='video-card'>
            <div className='thumbnail-container'>
              <a href='https://www.youtube.com/watch?v=fNVa1qMbF9Y'>
                <img className='thumbnail' src='/public/img/exercises/youtube/thumbnails/thumbnail-7.webp' />
              </a>
              <div className='video-time'>10:13</div>
            </div>
            <div className='video-info-grid'>
              <div className='channel-profile-pic'>
                <a href='https://www.youtube.com/channel/UCP5tjEmvPItGyLhmjdwP7Ww'>
                  <img src='/public/img/exercises/youtube/channel-profile-pics/channel-7.jpeg' />
                </a>
              </div>
              <div className='video-info'>
                <p className='video-title'>
                  <a className='video-link' href='https://www.youtube.com/watch?v=fNVa1qMbF9Y'>
                    Why Planes Don't Fly Over Tibet
                  </a>
                </p>
                <p className='channel-name'>
                  <a className='channel-link' href='https://www.youtube.com/channel/UCP5tjEmvPItGyLhmjdwP7Ww'>
                    RealLifeLore
                  </a>
                </p>
                <p className='video-stats'>
                  6.6M views &#183; 1 year ago
                </p>
              </div>
            </div>
          </div>

          <div className='video-card'>
            <div className='thumbnail-container'>
              <a href='https://www.youtube.com/watch?v=lFm4EM1juls'>
                <img className='thumbnail' src='/public/img/exercises/youtube/thumbnails/thumbnail-8.webp' />
              </a>
              <div className='video-time'>7:12</div>
            </div>
            <div className='video-info-grid'>
              <div className='channel-profile-pic'>
                <a href='https://www.youtube.com/channel/UCHAK6CyegY22Zj2GWrcaIxg'>
                  <img src='/public/img/exercises/youtube/channel-profile-pics/channel-8.jpeg' />
                </a>
              </div>
              <div className='video-info'>
                <p className='video-title'>
                  <a className='video-link' href='https://www.youtube.com/watch?v=lFm4EM1juls'>
                    Inside The World's Biggest Passenger Plane
                  </a>
                </p>
                <p className='channel-name'>
                  <a className='channel-link' href='https://www.youtube.com/channel/UCHAK6CyegY22Zj2GWrcaIxg'>
                    Tech Vision
                  </a>
                </p>
                <p className='video-stats'>
                  3.7M views &#183; 10 months ago
                </p>
              </div>
            </div>
          </div>

          <div className='video-card'>
            <div className='thumbnail-container'>
              <a href='https://www.youtube.com/watch?v=ixmxOlcrlUc'>
                <img className='thumbnail' src='/public/img/exercises/youtube/thumbnails/thumbnail-9.webp' />
              </a>
              <div className='video-time'>13:17</div>
            </div>
            <div className='video-info-grid'>
              <div className='channel-profile-pic'>
                <a href='https://www.youtube.com/c/OFFICIALTHENXSTUDIOS'>
                  <img src='/public/img/exercises/youtube/channel-profile-pics/channel-9.jpeg' />
                </a>
              </div>
              <div className='video-info'>
                <p className='video-title'>
                  <a className='video-link' href='https://www.youtube.com/watch?v=ixmxOlcrlUc'>
                    The SECRET to Super Human STRENGTH
                  </a>
                </p>
                <p className='channel-name'>
                  <a className='channel-link' href='https://www.youtube.com/c/OFFICIALTHENXSTUDIOS'>
                    ThenX
                  </a>
                </p>
                <p className='video-stats'>
                  20M views &#183; 3 year ago
                </p>
              </div>
            </div>
          </div>

          <div className='video-card'>
            <div className='thumbnail-container'>
              <a href='https://www.youtube.com/watch?v=R2vXbFp5C9o'>
                <img className='thumbnail' src='/public/img/exercises/youtube/thumbnails/thumbnail-10.webp' />
              </a>
              <div className='video-time'>7:53</div>
            </div>
            <div className='video-info-grid'>
              <div className='channel-profile-pic'>
                <a href='https://www.youtube.com/user/businessinsider'>
                  <img src='/public/img/exercises/youtube/channel-profile-pics/channel-10.jpeg' />
                </a>
              </div>
              <div className='video-info'>
                <p className='video-title'>
                  <a className='video-link' href='https://www.youtube.com/watch?v=R2vXbFp5C9o'>
                    How The World's Largest Cruise Ship Makes 30,000 Meals Every Day
                  </a>
                </p>
                <p className='channel-name'>
                  <a className='channel-link' href='https://www.youtube.com/user/businessinsider'>
                    Business Insider
                  </a>
                </p>
                <p className='video-stats'>
                  14M views &#183; 1 year ago
                </p>
              </div>
            </div>
          </div>

          <div className='video-card'>
            <div className='thumbnail-container'>
              <a href='https://www.youtube.com/watch?v=0nZuYyXET3s'>
                <img className='thumbnail' src='/public/img/exercises/youtube/thumbnails/thumbnail-11.webp' />
              </a>
              <div className='video-time'>4:10</div>
            </div>
            <div className='video-info-grid'>
              <div className='channel-profile-pic'>
                <a href='https://www.youtube.com/c/Destinationtips'>
                  <img src='/public/img/exercises/youtube/channel-profile-pics/channel-11.jpeg' />
                </a>
              </div>
              <div className='video-info'>
                <p className='video-title'>
                  <a className='video-link' href='https://www.youtube.com/watch?v=0nZuYyXET3s'>
                    Dubai's Crazy Underwater Train and Other Things #Only in Dubai
                  </a>
                </p>
                <p className='channel-name'>
                  <a className='channel-link' href='https://www.youtube.com/c/Destinationtips'>
                    Destination Tips
                  </a>
                </p>
                <p className='video-stats'>
                  3M views &#183; 1 year ago
                </p>
              </div>
            </div>
          </div>

          <div className='video-card'>
            <div className='thumbnail-container'>
              <a href='https://www.youtube.com/watch?v=9iMGFqMmUFs'>
                <img className='thumbnail' src='/public/img/exercises/youtube/thumbnails/thumbnail-12.webp' />
              </a>
              <div className='video-time'>4:51</div>
            </div>
            <div className='video-info-grid'>
              <div className='channel-profile-pic'>
                <a href='https://www.youtube.com/teded'>
                  <img src='/public/img/exercises/youtube/channel-profile-pics/channel-12.jpeg' />
                </a>
              </div>
              <div className='video-info'>
                <p className='video-title'>
                  <a className='video-link' href='https://www.youtube.com/watch?v=9iMGFqMmUFs'>
                    What would happen if you didn’t drink water? - Mia Nacamulli
                  </a>
                </p>
                <p className='channel-name'>
                  <a className='channel-link' href='https://www.youtube.com/teded'>
                    TED-Ed
                  </a>
                </p>
                <p className='video-stats'>
                  12M views &#183; 5 years ago
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
