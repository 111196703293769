import {CodeBlock} from '../../../../components/CodeBlock';

export const Section4Exercises = <>
  <div className='exercise'>
    <p>
      <strong>Exercise 1</strong><br />
      Change the title that shows up in the tab to "Your_Name's Todo App" (use your name).
    </p>
    <CodeBlock
      revealId='s4e1'
      language='html'
      highlightLines='3'
    >
{`<html>
  <head>
    <title>Simon's Todo App</title>
  </head>
  ...`}
      </CodeBlock>
  </div>

  <div className='exercise'>
    <p>
      <strong>Exercise 2</strong><br />
      Add your name to the top of the webpage using a div.
    </p>
    <CodeBlock
      revealId='s4e2'
      language='html'
      highlightLines='3'
    >
{`...
<body>
  <div>Simon</div>
  <button>Press me</button>
  <div>Get groceries</div>
  ...`}
    </CodeBlock>
  </div>

  <div className='exercise'>
    <p>
      <strong>Exercise 3</strong><br />
      Reverse the todo list by re-arranging the divs in the HTML. Notice that elements are rendered from top to bottom. Reverse the list back after to follow along with the rest of the tutorial.
    </p>
    <CodeBlock
      revealId='s4e3'
      language='html'
      lineNumbers
      highlightLines='4-6'
    >
{`...
<body>
  <button>Press me</button>
  <div>Make dinner</div>
  <div>Wash car</div>
  <div>Get groceries</div>
  ...`}
    </CodeBlock>
  </div>
</>;
