import {Helmet} from 'react-helmet-async';

export function CertificatesHTMLCSS() {
  return (
    <div className='CertificatesHTMLCSS container-xl mt-5'>
      <Helmet defer={false}>
        <title>HTML CSS Certificate Info | supersimple.dev</title>
        <meta
          name='description'
          content='Info about the HTML CSS certificate for SuperSimpleDev.'
        />
      </Helmet>
      <div className='row'>
        <div className='offset-md-2 col-md-8 offset-lg-3 col-lg-6'>
          <img className='certificate-screenshot' src='/images/certificate-html-css.jpg' />
          <h1 className='h4 fw-bold mt-4 mb-3'>
            HTML CSS Certificate Info
          </h1>

          <p>
            The HTML & CSS course associated with this certificate teaches the major skills that are required to create and style complex websites. The course has had over 10 millions views in 2 years and has received over 204k likes and 101k comments. <a href='https://www.youtube.com/watch?v=G3e-cpL7ofc' target='_blank' rel='noreferrer'>https://www.youtube.com/watch?v=G3e-cpL7ofc</a>
          </p>

          <p>
            Going beyond offering a general feature overview, the course adopts a hands-on approach, guiding students through the creation of a real-world project, and providing exercises after each lesson (with over 100 exercises in total). This ensures simultaneous learning and application.
          </p>

          <h2 className='h5 fw-bold mt-5 mb-3'>
            Key features learned and applied in the course include:
          </h2>

          <ul className='topics-list'>
            <li>
              HTML Elements and Syntax
            </li>
            <li>
              CSS Selectors and Syntax
            </li>
            <li>
              Hovers, Transitions, Shadows
            </li>
            <li>
              Chrome DevTools & Elements Tab
            </li>
            <li>
              CSS Box Model: Margin, Padding, and Border
            </li>
            <li>
              CSS Text Styles
            </li>
            <li>
              The HTML Structure
            </li>
            <li>
              Images and Text Boxes
            </li>
            <li>
              CSS Display Property
            </li>
            <li>
              HTML div Element
            </li>
            <li>
              Nested Layouts Technique
            </li>
            <li>
              CSS Grid
            </li>
            <li>
              Flexbox
            </li>
            <li>
              CSS Position Property
            </li>
            <li>
              Responsive Design
            </li>
          </ul>

          <h2 className='h5 fw-bold mt-5 mb-3'>
            Conclusion
          </h2>
          <p>
            The course culminates in the completion of recreating a real-world website.
          </p>

          <p>
            As such, candidates who have finished the course, passed the final test and received this certificate have not only displayed a foundational grasp of HTML & CSS features but have also demonstrated their practical application skills.
          </p>
          <p>
            <a href='https://courses.supersimple.dev/courses/html-css' className='btn btn-primary mt-3 me-3' >
              Enroll Now
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
