import c from 'classnames';
import React, {ReactNode, useState} from 'react';

interface Props {
  children: ReactNode;
  language: 'javascript' | 'css' | 'html';

  revealId?: string | boolean;
  revealText?: string;

  lineNumbers?: boolean;
  highlightLines?: string;
  fileName?: string;
  className?: string;
}

export function CodeBlock({
  children, language, revealId, revealText, lineNumbers, highlightLines, fileName, className
}: Props) {
  const [hidden, setHidden] = useState(!!revealId);

  return (
    <div className={c('CodeBlock', className || '', {
      'with-filename': !!fileName,
      'click-to-reveal-hidden': hidden
    })}>
      <div className='CodeBlock__filename'>{fileName}</div>

      <pre data-line={highlightLines}>
        <code className={c(`language-${language}`, {
          'line-numbers': lineNumbers
        })}>{children}</code>
      </pre>

      <div className='click-to-reveal-overlay' onClick={() => {
        setHidden(false);
      }}>
        <button className='btn btn-primary'>
          {revealText || 'Show Solution'}
        </button>
      </div>
    </div>
  );
}
