import {CodeBlock} from '../../../../components/CodeBlock';

export const Section8Exercises = <>
  <div className='exercise'>
    <strong>Exercise 1</strong><br />
    <p>Set up a <code>{`<button id="todo-button">To Do</button>`}</code> so that when you click the button, the text inside changes to "Done" (hint: use <code>.innerText</code>)</p>
    <CodeBlock
      revealId='s8e1'
      language='html'
      fileName='solution.html'
    >
{`<button id="todo-button" onclick="changeToDone()">To do</button>
<script>
  function changeToDone() {
    let button = document.getElementById('todo-button');
    button.innerText = 'Done';
  }
</script>`}
    </CodeBlock>
  </div>

  <div className='exercise'>
    <p>
      <strong>Exercise 2</strong><br />
      Set up a <code>{`<div id="counter">0</div>`}</code>, a button with the label "Up", and a variable: <code>let count = 0;</code> so that when clicking the button, the number in the div increases by 1. (hint: use <code>count = count + 1;</code>)
    </p>
    <CodeBlock
      revealId='s8e2'
      language='html'
      fileName='solution.html'
    >
{`<div id="counter">0</div>
<button onclick="countUp()">Up</button>
<script>
  let count = 0;

  function countUp() {
    count = count + 1;
    let counter = document.getElementById('counter');
    counter.innerText = count;
  }
</script>`}
    </CodeBlock>
  </div>

  <div className='exercise'>
    <p>
      <strong>Exercise 3</strong><br />
      Set up the same counter as above, but with another button "Down", that decreases the count by one. (hint: use <code>count = count - 1;</code>)
    </p>
    <CodeBlock
      revealId='s8e3'
      language='html'
      fileName='solution.html'
    >
{`<div id="counter">0</div>
<button onclick="countUp()">Up</button>
<button onclick="countDown()">Down</button>
<script>
  let count = 0;

  function countUp() {
    count = count + 1;
    updateCount();
  }

  function countDown() {
    count = count - 1;
    updateCount();
  }

  // Not necessary, but BONUS POINTS if you separated this
  // repeated code into a function!
  function updateCount() {
    let counter = document.getElementById('counter');
    counter.innerText = count;
  }
</script>`}
    </CodeBlock>
  </div>

  <div className='exercise'>
    <p>
      <strong>Exercise 4</strong><br />
      Create a button that takes what's inside the textbox, puts it in a div and adds the div to the page.
    </p>
    <CodeBlock
      revealId='s8e4'
      language='html'
      fileName='solution.html'
    >
{`<input type="text" id="todo-title" />
<button onclick="displayTitle()">Display Title</button>
<script>
  function displayTitle() {
    let textbox = document.getElementById('todo-title');
    let title = textbox.value;

    let div = document.createElement('div');
    div.innerText = title;
    document.body.appendChild(div);
  }
</script>`}
    </CodeBlock>
  </div>
</>;
