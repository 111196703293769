import {StaticRouterContext, Route, useRouteMatch} from 'react-router';
import {SwitchWith404} from '../../../components/SwitchWith404';
import {HTMLReference} from './HTMLReference';

interface Props {
  routerContext: StaticRouterContext
}

export function HTMLReferenceRouter({ routerContext }: Props) {
  let { path } = useRouteMatch();

  return (
    <SwitchWith404 routerContext={routerContext}>
      <Route exact path={path}>
        <HTMLReference />
      </Route>
    </SwitchWith404>
  );
}
