import {Helmet} from 'react-helmet-async';

export function ThankYouDonation() {
  return (
    <div className='ThankYouDonation container-xl mt-5'>
      <Helmet defer={false}>
        <title>Thank you for your donation! | supersimple.dev</title>
        <meta
          name='description'
          content="Thank you for your donation!"
        />
      </Helmet>
      <div className='row'>
        <div className='col'>
          <div className='thank-you-message'>
            <h1 className='h2 fw-bold mb-3'>
              Thank You!
            </h1>
            <p>
              Thank you so much for your donation!
            </p>
            <p>
              I really appreciate your support and will work hard to bring you more videos! You will receive a receipt of your donation in your email shortly.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
