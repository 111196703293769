import c from 'classnames';
import {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {CourseCard} from './CourseCard';

export function Courses() {
  const [mouseMoved, setMouseMoved] = useState(false);

  useEffect(() => {
    const onMouseMove = () => {
      setMouseMoved(true);
      window.removeEventListener('mousemove', onMouseMove);
    };

    window.addEventListener('mousemove', onMouseMove);

    return () => {
      window.removeEventListener('mousemove', onMouseMove);
    };
  }, []);

  return (
    <div className={c('Courses container-xl mt-5', {
      'mouse-moved': mouseMoved
    })}>
      <Helmet defer={false}>
        <title>Courses | supersimple.dev</title>
        <meta
          name='description'
          content='High-quality courses for learning the skills to become a software engineer.'
        />
      </Helmet>
      <div className='row'>
        <div className='col-sm-8 col-md-6 col-xl-4'>
          <CourseCard
            image={{
              src: '/public/img/courses/html-css-full-course-cover.png',
              alt: 'HTML CSS Full Course Logo'
            }}
            title='HTML & CSS Full Course'
            description='Learn to build websites with HTML and CSS. This is a full course that covers all levels from beginner to pro.'
            time='6 hour 31 mins'
            lessons='17 sections'
            exercises='107 exercises'
            courseLink='/courses/html-css-course'
            certificateLink='https://courses.supersimple.dev/courses/html-css'
          />
        </div>

        <div className='col-sm-8 col-md-6 col-xl-4'>
          <CourseCard
            image={{
              src: '/public/img/courses/github-pages-course-cover.png',
              alt: 'GitHub Pages Course Logo'
            }}
            title='GitHub Pages Course'
            description='Learn how to put your HTML and CSS website on the Internet so that anyone can access it. We also learn how to set up a domain name for your website (like "mywebsite.com").'
            time='29 mins'
            lessons='2 sections'
            exercises='10 exercises'
            courseLink='/courses/github-pages-course'
          />
        </div>

        <div className='col-sm-8 col-md-6 col-xl-4'>
          <CourseCard
            image={{
              src: '/public/img/courses/javascript-course-logo.svg',
              alt: 'JavaScript Beginner Course Logo'
            }}
            title='JavaScript for Beginners'
            description='Designed for beginners (even with 0 coding experience). We learn JavaScript and build an app from the ground up.'
            time='1 hour 45 mins'
            lessons='16 sections'
            exercises='43 exercises'
            courseLink='/courses/javascript-beginner-course-20201'
          />
        </div>

        <div className='col-sm-8 col-md-6 col-xl-4'>
          <CourseCard
            image={{
              src: '/public/img/courses/git-github-course-logo.svg',
              alt: 'Git and GitHub Complete Course logo'
            }}
            title='Git and GitHub - Complete Course'
            description='Comprehensive course on Git and GitHub. We learn from 0 experience to professional.'
            time='1 hour 33 mins'
            lessons='14 sections'
            courseLink='/courses/git-github-complete-course-20211'
          />
        </div>
      </div>
    </div>
  );
}
