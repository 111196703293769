import {CodeBlock} from '../../../../components/CodeBlock';
import {ScrollLink} from '../../../../components/ScrollLink';

export const Section15Exercises = <>
  <div className='exercise'>
    <p>
      <strong>Exercise 1</strong><br />
      Note: make a copy of the code in progress and add to it. The code in progress can be found <ScrollLink to='code-in-progress'>here</ScrollLink>
    </p>

    <p>We’ll add the ability to edit todos. (Warning: this one is challenging, but we already learned everything we need. You can do it! Answer is in description).</p>

    <ol>
      <li>We'll add a new property <code>.isEditing</code> to each todo. This property starts as <code>undefined</code>. When rendering, <code>if (todo.isEditing === true)</code>, don’t render what we had before, instead render a new textbox, a new date picker, and an "Update" button. Else, render what we had before, except add an "Edit" button before the "Delete" button.</li>
      <li>When clicking the "Edit" button, find the todo and change <code>todo.isEditing = true</code>, then rerender. Hint: when creating the "Edit" button, save the todo id on to the button using <code>button.dataset.todoId = todo.id;</code> You can retrieve this id in the click handler function using <code>button.dataset</code></li>
      <li>When clicking the "Update" button, find the associated textbox and date picker (hint: set <code>todo.dataset.todoId</code> when creating these 2 elements), grab the value inside the textbox and date picker, find the todo, update the todo with the new values, set <code>todo.isEditing = false</code>, and rerender.</li>
    </ol>
    <video src='/public/courses/jsbeginner20201/s15e1.mov' muted controls style={{width: 480}}
      className='my-2'>
      <source src='/public/courses/jsbeginner20201/s15e1.mov' type='video/mp4' />
      Your browser does not support the video tag.
    </video>
    <CodeBlock
      revealId='s15e1'
      language='html'
      fileName='solution.html'
      lineNumbers
      highlightLines='67-87, 113-135, 145-182'
    >
{`<html>
  <head>
    <title>My Todo App</title>
  </head>
  <body>
    <input id="todo-title" type="text" />
    <input id="date-picker" type="date" />
    <button onclick="addTodo()">Add Todo</button>

    <div id="todo-list"></div>

    <script>
      // Model
      // If localstorage has a todos array, then use it
      // Otherwise use the default array.
      let todos;

      // Retrieve localStorage
      const savedTodos = JSON.parse(localStorage.getItem('todos'));
      // Check if it's an array
      if (Array.isArray(savedTodos)) {
        todos = savedTodos;
      } else {
        todos = [{
          title: 'Get groceries',
          dueDate: '2021-10-04',
          id: 'id1'
        }, {
          title: 'Wash car',
          dueDate: '2021-02-03',
          id: 'id2'
        }, {
          title: 'Make dinner',
          dueDate: '2021-03-04',
          id: 'id3'
        }];
      }

      // Creates a todo
      function createTodo(title, dueDate) {
        const id = '' + new Date().getTime();

        todos.push({
          title: title,
          dueDate: dueDate,
          id: id
        });

        saveTodos();
      }

      // Deletes a todo
      function removeTodo(idToDelete) {
        todos = todos.filter(function (todo) {
          // If the id of this todo matches idToDelete, return false
          // For everything else, return true
          if (todo.id === idToDelete) {
            return false;
          } else {
            return true;
          }
        });

        saveTodos();
      }

      function setEditing(todoId) {
        todos.forEach(function (todo) {
          if (todo.id === todoId) {
            todo.isEditing = true;
          }
        });

        saveTodos();
      }

      function updateTodo(todoId, newTitle, newDate) {
        todos.forEach(function (todo) {
          if (todo.id === todoId) {
            todo.title = newTitle;
            todo.dueDate = newDate;
            todo.isEditing = false;
          }
        });

        saveTodos();
      }

      function saveTodos() {
        localStorage.setItem('todos', JSON.stringify(todos));
      }

      // Controller
      function addTodo() {
        const textbox = document.getElementById('todo-title');
        const title = textbox.value;

        const datePicker = document.getElementById('date-picker');
        const dueDate = datePicker.value;

        createTodo(title, dueDate);
        render();
      }

      function deleteTodo(event) {
        const deleteButton = event.target;
        const idToDelete = deleteButton.id;

        removeTodo(idToDelete);
        render();
      }

      // I forgot to mention: usually for these click handler function we like to name them
      // starting  with "on" (onAdd, onDelete, onEdit, etc.) I'll revise for the 2022 tutorial!
      function onEdit(event) {
        const editButton = event.target;
        const todoId = editButton.dataset.todoId;

        setEditing(todoId);
        render();
      }

      function onUpdate(event) {
        const updateButton = event.target;
        const todoId = updateButton.dataset.todoId;

        const textbox = document.getElementById('edit-title-' + todoId);
        const newTitle = textbox.value;

        const datePicker = document.getElementById('edit-date-' + todoId);
        const newDate = datePicker.value;

        updateTodo(todoId, newTitle, newDate);
        render();
      }

      // View
      function render() {
        // reset our list
        document.getElementById('todo-list').innerHTML = '';

        todos.forEach(function (todo) {
          const element = document.createElement('div');

          // If this todo is being edited, render a textbox, date picker and a
          // button for saving the edits.
          if (todo.isEditing === true) {
            const textbox = document.createElement('input');
            textbox.type = 'text';
            textbox.id = 'edit-title-' + todo.id;
            element.appendChild(textbox);

            const datePicker = document.createElement('input');
            datePicker.type = 'date';
            datePicker.id = 'edit-date-' + todo.id;
            element.appendChild(datePicker);

            const updateButton = document.createElement('button');
            updateButton.innerText = 'Update';
            updateButton.dataset.todoId = todo.id;
            updateButton.onclick = onUpdate;
            element.appendChild(updateButton);

          // If this todo is not being edited, render what we had before
          // and add an "Edit" button.
          } else {
            element.innerText = todo.title + ' ' + todo.dueDate;

            const editButton = document.createElement('button');
            editButton.innerText = 'Edit';
            editButton.style = 'margin-left: 12px';
            editButton.onclick = onEdit;
            editButton.dataset.todoId = todo.id;
            element.appendChild(editButton);

            const deleteButton = document.createElement('button');
            deleteButton.innerText = 'Delete';
            deleteButton.style = 'margin-left: 12px';
            deleteButton.onclick = deleteTodo;
            deleteButton.id = todo.id;
            element.appendChild(deleteButton);
          }

          const todoList = document.getElementById('todo-list');
          todoList.appendChild(element);
        });
      }

      render();
    </script>
  </body>
</html>`}
    </CodeBlock>
  </div>
</>;
