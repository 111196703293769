import {Helmet} from 'react-helmet-async';

export function CertificatesJavaScript() {
  return (
    <div className='CertificatesJavaScript container-xl mt-5'>
      <Helmet defer={false}>
        <title>JavaScript Certificate Info | supersimple.dev</title>
        <meta
          name='description'
          content='Info about the JavaScript certificate for SuperSimpleDev.'
        />
      </Helmet>
      <div className='row'>
        <div className='offset-md-2 col-md-8 offset-lg-3 col-lg-6'>
          <img className='certificate-screenshot' src='/images/certificate-javascript.png' />
          <h1 className='h4 fw-bold mt-4 mb-3'>
            JavaScript Certificate Info
          </h1>
          <p>
            The Javascript course associated with this certificate teaches the major skills that are required to create complex, interactive websites with JavaScript, HTML, and CSS. The course has already had over 350k views in 3 weeks and has received over 14k likes and 1k comments. <a href='https://www.youtube.com/watch?v=EerdGm-ehJQ' target='_blank' rel='noreferrer'>https://www.youtube.com/watch?v=EerdGm-ehJQ</a><br />
          </p>

          <p>
            Going beyond offering a general feature overview, the course adopts a hands-on approach, guiding students through the creation of a real-world project, and providing exercises after each lesson (with over 250 exercises in total). This ensures simultaneous learning and application.
          </p>

          <h2 className='h5 fw-bold mt-5 mb-3'>
            Key features learned and applied in the course include:
          </h2>

          <ul className='topics-list'>
            <li>
              JavaScript Basics: Numbers, Math, Strings, Variables, Booleans, If-Statements, Functions, and Objects
            </li>
            <li>
              Arrays and Loops
            </li>
            <li>
              Document Object Model (DOM)
            </li>
            <li>
              HTML, CSS, and JavaScript Together
            </li>
            <li>
              Functions as Values
            </li>
            <li>
              Intro to Git
            </li>
            <li>
              Modules
            </li>
            <li>
              External Libraries
            </li>
            <li>
              Automated Testing and Testing Frameworks
            </li>
            <li>
              Object-Oriented Programming, Classes, and Inheritance
            </li>
            <li>
              Intro to Backend and HTTP Requests
            </li>
            <li>
              Callbacks, Promises, and Async Await
            </li>
          </ul>

          <h2 className='h5 fw-bold mt-5 mb-3'>
            Conclusion
          </h2>
          <p>
            The course culminates in the completion of recreating a real-world project.
          </p>

          <p>
            As such, candidates who have finished the course, passed the final test and received this certificate have not only displayed a foundational grasp of web development principles but have also demonstrated their practical application skills.
          </p>
          <p>
            <a href='https://courses.supersimple.dev/courses/javascript' className='btn btn-primary mt-3 me-3' >
              Enroll Now
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
