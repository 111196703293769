import {CodeBlock} from '../../../../components/CodeBlock';

export const Section9Code = <>
<CodeBlock
  language='html'
  fileName='todo.html'
  lineNumbers
  className='mt-0'
>{`<html>
  <head>
    <title>My Todo App</title>
  </head>
  <body>
    <input id="todo-title" type="text" />
    <button onclick="addTodo()">Add Todo</button>

    <div id="todo-list"></div>

    <script>
      const todos = ['Get groceries', 'Wash car', 'Make dinner'];

      render();

      function addTodo() {
        const textbox = document.getElementById('todo-title');
        const title = textbox.value;
        todos.push(title);

        render();
      }

      function render() {
        // reset our list
        document.getElementById('todo-list').innerHTML = '';

        todos.forEach(function (todoTitle) {
          const element = document.createElement('div');
          element.innerText = todoTitle;
          const todoList = document.getElementById('todo-list');
          todoList.appendChild(element);
        });
      }
    </script>
  </body>
</html>`}</CodeBlock>
</>;
