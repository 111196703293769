import c from 'classnames';
import {Helmet} from 'react-helmet-async';
import { ChangeEventHandler, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { DonationAmount, getDonationCancelUrl, getDonationPriceId, getDonationSuccessUrl, loadStripe } from '../utils/stripe';

export function Donate() {
  const [isLoading, setIsLoading] = useState(false);
  const [customDonation, setCustomDonation] = useState('');
  const [customDonationError, setCustomDonationError] = useState('');

  const handleCustomDonation: ChangeEventHandler<HTMLInputElement> = (e) => {
    setCustomDonation(e.target.value);
  };

  const handleDonate = (presetDonation?: DonationAmount) => async () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    setCustomDonationError('');

    // TODO: report to sentry if stripe doesn't exist.
    const stripe = await loadStripe();
    if (!stripe) {
      setIsLoading(false);
      return;
    }

    try {
      if (presetDonation) {
        await stripe.redirectToCheckout({
          lineItems: [{
            price: getDonationPriceId(presetDonation),
            quantity: 1,
          }],
          mode: 'payment',
          successUrl: getDonationSuccessUrl(),
          cancelUrl: getDonationCancelUrl(),
        });

      } else {
        const response = await axios.post('/api/stripe/donation', {
          amount: customDonation
        });

        const sessionId = response.data.sessionId;
        await stripe.redirectToCheckout({
          sessionId
        });
      }

    } catch (err) {
      const error: AxiosError = err as AxiosError;
      const customMessage = error?.response?.data?.customMessage;
      if (customMessage) {
        setCustomDonationError(customMessage);
      }
    }

    setIsLoading(false);
  };

  return (
    <div className='SupportUs container-xl mt-5'>
      <Helmet defer={false}>
        <title>Support Us | supersimple.dev</title>
        <meta
          name='description'
          content='Support the SuperSimpleDev channel'
        />
      </Helmet>

      <div className='donation-row'>
        <div className='donation-col'>
          <h1 className='h2 fw-bold mb-3'>
            Donate
          </h1>
          <p>
            Thanks for supporting the SuperSimpleDev channel!<br />
          </p>
          <p>
            I teach coding because I believe it can completely transform a person's life and help them become financially secure.
          </p>
          <p>
            Your donation will help cover the costs of running the website and will help purchase necessary equipment and software for making videos.
          </p>
          <p className='text-muted'>
            (Note: SuperSimpleDev is not an officially registered charity so a tax slip currently cannot be provided for your donation)
          </p>

          <div className='donation-options mt-4'>
            <button className='btn btn-outline-primary'
              onClick={handleDonate('5')}>
              $5
            </button>
            <button className='btn btn-outline-primary'
              onClick={handleDonate('10')}>
              $10
            </button>
            <button className='btn btn-outline-primary'
              onClick={handleDonate('20')}>
              $20
            </button>
            <input type='number' placeholder='Custom amount'
              onChange={handleCustomDonation}
              className={c('custom-donation-input form-control', {
                'is-invalid': !!customDonationError
              })} />

            <button className='btn btn-primary donate-button'
              onClick={handleDonate()}>
              {isLoading
                ? <span className='spinner-border spinner-border-sm' />
                : 'Donate'
              }
            </button>
          </div>

          {!!customDonationError &&
            <div className='alert alert-danger mt-3'>
              {customDonationError}
            </div>
          }
        </div>
        <div className='picture-col'>
          <img src='/public/img/youtube-profile-pic.png'
            alt='Photo of Simon at SuperSimpleDev'
            className='profile-picture' />
        </div>
      </div>
    </div>
  );
}
