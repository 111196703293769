import {Helmet} from 'react-helmet-async';
import {Link} from 'react-router-dom';

export function Home() {
  return (
    <div className='container-xl'>
      <Helmet defer={false}>
        <title>supersimple.dev | Learn Software Engineering</title>
        <meta
          name='description'
          content='High-quality software engineering and interviewing lessons | Become a software engineer or advance in your current career.'
        />
      </Helmet>
      <div className='row' style={{
        height: '70vh'
      }}>
        <div className='col d-flex align-items-center'>
          <div>
            <h1 className='fw-bold h2'>Become a Software Engineer</h1>
            <p className='mb-4 mt-3'>
              Hey, I'm Simon <img src='/public/img/hand-wave.png' alt='hand wave' style={{
                height: 30,
                marginTop: -12,
                marginLeft: 4
              }} />
              <br />
              I want to make a tech career possible for anyone.
            </p>
            <Link to='/courses' className='btn btn-primary'>
              Get Started
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
