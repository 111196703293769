import c from 'classnames';
import {useEffect, useRef} from 'react';
import {Helmet} from 'react-helmet-async';
import {Accordion} from '../../../components/Accordion/Accordion';
import {YouTubeVideo} from '../../../components/YouTubeVideo';
import {useScrollSpy} from '../../../hooks/useScrollSpy';
import {Section10Code} from './code/Section10Code';
import {Section11Code} from './code/Section11Code';
import {Section12Code} from './code/Section12Code';
import {Section13Code} from './code/Section13Code';
import {Section14Code} from './code/Section14Code';
import {Section15Code} from './code/Section15Code';
import {Section16Code} from './code/Section16Code';
import {Section2Code} from './code/Section2Code';
import {Section3Code} from './code/Section3Code';
import {Section4Code} from './code/Section4Code';
import {Section5Code} from './code/Section5Code';
import {Section6Code} from './code/Section6Code';
import {Section7Code} from './code/Section7Code';
import {Section8Code} from './code/Section8Code';
import {Section9Code} from './code/Section9Code';
import {Section10Exercises} from './exercises/Section10Exercises';
import {Section11Exercises} from './exercises/Section11Exercises';
import {Section12Exercises} from './exercises/Section12Exercises';
import {Section13Exercises} from './exercises/Section13Exercises';
import {Section14Exercises} from './exercises/Section14Exercises';
import {Section15Exercises} from './exercises/Section15Exercises';
import {Section16Exercises} from './exercises/Section16Exercises';
import {Section1Exercises} from './exercises/Section1Exercises';
import {Section2Exercises} from './exercises/Section2Exercises';
import {Section3Exercises} from './exercises/Section3Exercises';
import {Section4Exercises} from './exercises/Section4Exercises';
import {Section5Exercises} from './exercises/Section5Exercises';
import {Section6Exercises} from './exercises/Section6Exercises';
import {Section7Exercises} from './exercises/Section7Exercises';
import {Section8Exercises} from './exercises/Section8Exercises';
import {Section9Exercises} from './exercises/Section9Exercises';

export function JSBeginner20201() {
  const activeLink = useScrollSpy('.Course__nav a');

  const contentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (contentRef.current) Prism.highlightAllUnder(contentRef.current);
  }, []);

  return (
    <div className='Course container-xl'>
      <Helmet defer={false}>
        <title>JavaScript Beginner Course | supersimple.dev</title>
        <meta
          name='description'
          content='Learn JavaScript by building an app from the ground up.'
        />
      </Helmet>

      <div className='Course__nav'>
        <nav>
          <div className='nav-title'>JS Beginner Course</div>
          <a className={c({
            active: activeLink === 'exercises-solutions'
          })} href='#exercises-solutions'>
            Exercises &amp; Solutions
          </a>
          <a className={c({
            active: activeLink === 'cheatsheet'
          })} href='#cheatsheet'>
            Cheatsheet
          </a>
          <a className={c({
            active: activeLink === 'code'
          })} href='#code'>
            Code
          </a>
        </nav>
      </div>

      <main className='Course__content' ref={contentRef}>
        <section id='exercises-solutions'>
          <h2>
            Exercise &amp; Solutions
          </h2>
          <p>
            After each section of the course, try the exercises below to practice what you learned.
          </p>

          <Accordion
            id='exercises-solutions-accordion'
            className='mt-4'
            items={[{
              heading: 'Section 1',
              body: Section1Exercises
            }, {
              heading: 'Section 2',
              body: Section2Exercises
            }, {
              heading: 'Section 3',
              body: Section3Exercises
            }, {
              heading: 'Section 4',
              body: Section4Exercises
            }, {
              heading: 'Section 5',
              body: Section5Exercises
            }, {
              heading: 'Section 6',
              body: Section6Exercises
            }, {
              heading: 'Section 7',
              body: Section7Exercises
            }, {
              heading: 'Section 8',
              body: Section8Exercises
            }, {
              heading: 'Section 9',
              body: Section9Exercises
            }, {
              heading: 'Section 10',
              body: Section10Exercises
            }, {
              heading: 'Section 11',
              body: Section11Exercises
            }, {
              heading: 'Section 12',
              body: Section12Exercises
            }, {
              heading: 'Section 13',
              body: Section13Exercises
            }, {
              heading: 'Section 14',
              body: Section14Exercises
            }, {
              heading: 'Section 15',
              body: Section15Exercises
            }, {
              heading: 'Section 16',
              body: Section16Exercises
            }]}
          />
        </section>

        <section id='cheatsheet' className='mt-6'>
          <h2>
            Cheatsheet
          </h2>
          <a href='https://supersimpledev.github.io/references/javascript-reference.pdf'
            className='btn btn-primary btn-sm'
            target='_blank' rel='noreferrer'>
            Download Cheatsheet
          </a>
        </section>

        <section id='code' className='mt-6'>
          <h2>
            Code
          </h2>
          <p>
            Here's a copy of the code at the end of each section.
          </p>

          <Accordion
            id='code-accordion'
            className='mt-4'
            items={[{
              heading: 'Section 2',
              body: Section2Code
            }, {
              heading: 'Section 3',
              body: Section3Code
            }, {
              heading: 'Section 4',
              body: Section4Code
            }, {
              heading: 'Section 5',
              body: Section5Code
            }, {
              heading: 'Section 6',
              body: Section6Code
            }, {
              heading: 'Section 7',
              body: Section7Code
            }, {
              heading: 'Section 8',
              body: Section8Code
            }, {
              heading: 'Section 9',
              body: Section9Code
            }, {
              heading: 'Section 10',
              body: Section10Code
            }, {
              heading: 'Section 11',
              body: Section11Code
            }, {
              heading: 'Section 12',
              body: Section12Code
            }, {
              heading: 'Section 13',
              body: Section13Code
            }, {
              heading: 'Section 14',
              body: Section14Code
            }, {
              heading: 'Section 15',
              body: Section15Code
            }, {
              heading: 'Section 16',
              body: Section16Code
            }]}
          />
        </section>
      </main>
    </div>
  );
}
