import { Stripe, loadStripe as _loadStripe } from '@stripe/stripe-js';
import { isProduction } from './env';

let stripe: Stripe;

export type DonationAmount = '5' | '10' | '20';

export function getDonationSuccessUrl() {
  return isProduction
    ? 'https://supersimple.dev/thank-you/donation'
    : `http://${window.location.host}/thank-you/donation`;
}

export function getDonationCancelUrl() {
  return isProduction
    ? 'https://supersimple.dev/donate'
    : `http://${window.location.host}/donate`;
}

export function getDonationPriceId(amount: DonationAmount) {
  if (isProduction) {
    return {
      '5': 'price_1KtJ6tFAr5RFSUV12ofv4OcM',
      '10': 'price_1KtJ6tFAr5RFSUV1ecXAsxdC',
      '20': 'price_1KtJ6tFAr5RFSUV11pmjB9Ba'
    }[amount];
  }

  return {
    '5': 'price_1KsebSFAr5RFSUV1xSmCJFvB',
    '10': 'price_1KtBYpFAr5RFSUV14X0T13jE',
    '20': 'price_1KtBYyFAr5RFSUV1w0Sp09HB'
  }[amount];
}

export async function loadStripe() {
  if (stripe) return stripe;

  const publicKey = isProduction
    ? 'pk_live_51KsJU8FAr5RFSUV1CHcmPWVsfbzO9ySH2k8XNINtTjnpIfxdp1EJyrhTxTTiCbfAwjy6bHqr9ZYn7GMJLHqrtVkW00jRYMwrHv'
    : 'pk_test_51KsJU8FAr5RFSUV18PvRfKOsb7fu50KPwqsBxZyt0l8zbcqZoKPQokGtRb28yK3YtIQ4YNvLtCNpel442eA54ftl00rJNuFzrV';

  let newStripe = await _loadStripe(publicKey);

  // TODO: log to Sentry if stripe doesn't exist.
  if (newStripe) stripe = newStripe;
  return stripe;
}
