import { ReactNode } from 'react';
import {Switch, Route, StaticRouterContext} from 'react-router';
import {NotFound} from '../routes/NotFound';

interface Props {
  children: ReactNode;
  routerContext: StaticRouterContext;
}

export function SwitchWith404({ children, routerContext }: Props) {
  return (
    <Switch>
      {children}
      <Route>
        <NotFound routerContext={routerContext} />
      </Route>
    </Switch>
  );
}
