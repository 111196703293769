import {CodeBlock} from '../../../../components/CodeBlock';

export const Section2Exercises = <>
  <div className='exercise'>
    <p>
      <strong>Exercise 1</strong><br />
      Create 2 buttons next to each other, one with the label "Up" and another with the label "Down".
    </p>
    <CodeBlock
      revealId='s2e1'
      language='html'
    >
{`<button>Up</button>
<button>Down</button>`}
    </CodeBlock>
  </div>

  <div className='exercise'>
    <p>
      <strong>Exercise 2</strong><br />
      Load Javascript from within the HTML and <code>console.log</code> your name.
    </p>
    <CodeBlock
      revealId='s2e2'
      language='html'
    >
{`<script>
  console.log('Simon');
</script>`}
    </CodeBlock>
  </div>
</>;
