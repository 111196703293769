import {CodeBlock} from '../../../../components/CodeBlock';

export const Section5Exercises = <>
  <div className='exercise'>
    <p>
      <strong>Exercise 1</strong><br />
      Use Javascript to add a button containing the text "Click Me" inside the button.
    </p>
    <CodeBlock
      revealId='s5e1'
      language='javascript'>
{`let button = document.createElement('button');
button.innerText = 'Click me';
document.body.appendChild(button);`}
    </CodeBlock>
  </div>

  <div className='exercise'>
    <p>
      <strong>Exercise 2</strong><br />
      Use Javascript to change the title of the webpage that shows up in the tabs (use Google to find the code for doing this).
    </p>
    <CodeBlock
      revealId='s5e2'
      language='javascript'
    >{`document.title = 'Some other title';`}</CodeBlock>
  </div>

  <div className='exercise'>
    <p>
      <strong>Exercise 3</strong><br />
      Reverse the order of the three todo items on the webpage by re-arranging the Javascript code. Notice how instructions are run one by one and elements are also created one by one.
    </p>
    <CodeBlock
      revealId='s5e3'
      language='javascript'
      highlightLines='6,10,14'>
{`let todo1 = 'Get groceries';
let todo2 = 'Wash car';
let todo3 = 'Make dinner';

let element = document.createElement('div');
element.innerText = todo3;
document.body.appendChild(element);

element = document.createElement('div');
element.innerText = todo2;
document.body.appendChild(element);

element = document.createElement('div');
element.innerText = todo1;
document.body.appendChild(element);`}
    </CodeBlock>
  </div>
</>;
