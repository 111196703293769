import {useRouteMatch, Route, StaticRouterContext} from 'react-router';
import {SwitchWith404} from '../../components/SwitchWith404';
import {Courses} from './Courses';
import {GitGithub20211} from './GitGithub2021/GitGithub2021';
import {JSBeginner20201} from './JSBeginner20201/JSBeginner20201';
import {HTMLCSSCourse2022} from './HTMLCSSCourse2022/HTMLCSSCourse2022';
import {GitHubPagesCourse2022} from './GitHubPagesCourse2022/GitHubPagesCourse2022';

interface Props {
  routerContext: StaticRouterContext;
}

export function CourseRouter({ routerContext }: Props) {
  const match = useRouteMatch();

  return (
    <SwitchWith404 routerContext={routerContext}>
      <Route path={`${match.path}/html-css-course`}>
        <HTMLCSSCourse2022 />
      </Route>
      <Route path={`${match.path}/github-pages-course`}>
        <GitHubPagesCourse2022 />
      </Route>
      <Route path={`${match.path}/javascript-beginner-course-20201`}>
        <JSBeginner20201 />
      </Route>
      <Route path={`${match.path}/git-github-complete-course-20211`}>
        <GitGithub20211 />
      </Route>
      <Route path={match.path}>
        <Courses />
      </Route>
    </SwitchWith404>
  );
}
