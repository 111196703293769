import {CodeBlock} from '../../../../components/CodeBlock';

export const Section16Exercises = <>
  <div className='exercise'>
    <p>
      <strong>Exercise 1</strong><br />
      Convert our functions to arrow functions (remember we also use functions in <code>todos.filter</code> and <code>todos.forEach</code>). Re-run the code and make sure things still work.
    </p>
  </div>

  <div className='exercise'>
    <p>
      <strong>Exercise 2</strong><br />
      Remember when we click the "Delete" button, we use <code>event.target</code> to get the button that was clicked and then get the todo id from the button. This time, we’ll use something called a "closure" to achieve the same goal.
    </p>

    <p>Write a function <code>onDelete</code> that takes 1 parameter <code>todoToDelete</code> which is a todo object, and <strong>returns a function</strong>. The inner function contains the code to remove the todo, but instead of using <code>event.target</code> to get the id, just use the <code>todoToDelete</code> parameter <strong>from the outer function</strong>.</p>

    <p>When creating the "Delete" button, set <code>deleteButton.onclick = onDelete(todo);</code> The result of <code>onDelete(todo)</code> <strong>will be a function itself</strong>, which can then be used with <code>.onclick</code>. Notice the inner function has access to the <code>todoToDelete</code> parameter from the outer function. This is called a "closure". Run the code and make sure deleting still works.</p>
    <CodeBlock
      revealId='s16e2'
      language='html'
      fileName='solution.html'
      lineNumbers
      highlightLines='83-100, 116-125'
      className='mb-6'
    >
{`<html>
  <head>
    <title>My Todo App</title>
  </head>
  <body>
    <input id="todo-title" type="text" />
    <input id="date-picker" type="date" />
    <button onclick="addTodo()">Add Todo</button>

    <div id="todo-list"></div>

    <script>
      // Model
      // If localstorage has a todos array, then use it
      // Otherwise use the default array.
      let todos;

      // Retrieve localStorage
      const savedTodos = JSON.parse(localStorage.getItem('todos'));
      // Check if it's an array
      if (Array.isArray(savedTodos)) {
        todos = savedTodos;
      } else {
        todos = [{
          title: 'Get groceries',
          dueDate: '2021-10-04',
          id: 'id1'
        }, {
          title: 'Wash car',
          dueDate: '2021-02-03',
          id: 'id2'
        }, {
          title: 'Make dinner',
          dueDate: '2021-03-04',
          id: 'id3'
        }];
      }

      // Creates a todo
      const createTodo = (title, dueDate) => {
        const id = '' + new Date().getTime();

        todos.push({
          title: title,
          dueDate: dueDate,
          id: id
        });

        saveTodos();
      }

      // Deletes a todo
      const removeTodo = idToDelete => {
        todos = todos.filter(todo => {
          // If the id of this todo matches idToDelete, return false
          // For everything else, return true
          if (todo.id === idToDelete) {
            return false;
          } else {
            return true;
          }
        });

        saveTodos();
      }

      const saveTodos = () => {
        localStorage.setItem('todos', JSON.stringify(todos));
      }

      // Controller
      const addTodo = () => {
        const textbox = document.getElementById('todo-title');
        const title = textbox.value;

        const datePicker = document.getElementById('date-picker');
        const dueDate = datePicker.value;

        createTodo(title, dueDate);
        render();
      }

      /* ❌ this code can now be deleted
      const deleteTodo = event => {
        const deleteButton = event.target;
        const idToDelete = deleteButton.id;

        removeTodo(idToDelete);
        render();
      }
      */

      // This is a closure (a function that returns another
      // function. The inner has access to the outer function's
      // parameters and variables).
      const onDelete = todoToDelete => {
        return () => {
          removeTodo(todoToDelete.id);
          render();
        };
      };

      // View
      const render = () => {
        // reset our list
        document.getElementById('todo-list').innerHTML = '';

        todos.forEach(todo => {
          const element = document.createElement('div');
          element.innerText = todo.title + ' ' + todo.dueDate;

          const deleteButton = document.createElement('button');
          deleteButton.innerText = 'Delete';
          deleteButton.style = 'margin-left: 12px';

          /* The previous way we did it, deleteTodo does not have
            access to the todo parameter in this for each loop.
            ❌ this code can now be deleted
          deleteButton.onclick = deleteTodo;
          deleteButton.id = todo.id;
          */

          // Closures let you create functions and give them access to
          // variables they normally wouldn't have access to.
          deleteButton.onclick = onDelete(todo);
          element.appendChild(deleteButton);

          const todoList = document.getElementById('todo-list');
          todoList.appendChild(element);
        });
      }

      render();
    </script>
  </body>
</html>`}
    </CodeBlock>
  </div>
</>;
