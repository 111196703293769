import {AppleIcon} from "../../../icons/bootstrap-1.4.0/AppleIcon";
import {YouTubeIcon} from "../../../icons/bootstrap-1.4.0/YouTubeIcon";

export function TextExercises() {
  return <div className='Exercises-Text'>
    <div className='youtube-text'>
      <YouTubeIcon />

      <p className='video-title'>
        Talking Tech and AI with Google CEO Sundar Pichai!
      </p>
      <p className='video-stats'>
        3.4M views &#183; 6 months ago
      </p>
      <p className='channel-name'>
        Marques Brownlee &#10003;
      </p>
      <p className='video-description'>
        Talking tech and AI on the heels of Google I/O. Also a daily driver phone reveal from Google's CEO. Shoutout to Sundar!
      </p>
    </div>

    <div className='apple-text'>
      <AppleIcon />

      <p className='apple-banner'>
        Shop early for the best selection of holiday favourites. <span className='shop-link'>Shop now &#62;</span>
      </p>
    </div>
  </div>;
}
