import c from 'classnames';
import { useEffect, useRef } from 'react';
import {Helmet} from 'react-helmet-async';
import {YouTubeVideo} from '../../../components/YouTubeVideo';
import {useScrollSpy} from '../../../hooks/useScrollSpy';

export function HTMLCSSCourse2022() {
  const activeLink = useScrollSpy('.Course__nav a');

  const contentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (contentRef.current) Prism.highlightAllUnder(contentRef.current);
  }, []);

  return (
    <div className='Course container-xl'>
      <Helmet defer={false}>
        <title>HTML CSS Course | supersimple.dev</title>
        <meta
          name='description'
          content='Learn HTML and CSS from the basics to advanced skills.'
        />
      </Helmet>

      <div className='Course__nav'>
        <nav>
          <div className='nav-title'>HTML CSS Full Course</div>
          <a className={c({
            active: activeLink === 'exercises-solutions'
          })} href='#exercises-solutions'>
            Exercises &amp; Solutions
          </a>
          <a className={c({
            active: activeLink === 'code'
          })} href='#code'>
            Code
          </a>
        </nav>
      </div>

      <main className='Course__content' ref={contentRef}>
        <section id='exercises-solutions'>
          <h2>
            Exercise &amp; Solutions
          </h2>
          <p>
            After each section of the course, try the exercises to practice what you learned.
          </p>

          <a href="https://github.com/SuperSimpleDev/html-css-course-2022/tree/main/1-exercise-solutions"
            target="_blank"
            rel="noreferrer">
            View exercise solutions
          </a>
        </section>

        <section id='code' className='mt-6'>
          <h2>
            Code
          </h2>
          <p>
            Here's a copy of the code at the end of each lesson.
          </p>

          <a href="https://github.com/SuperSimpleDev/html-css-course-2022/tree/main/2-copy-of-code"
            target="_blank"
            rel="noreferrer">
            View the code
          </a>
        </section>
      </main>
    </div>
  );
}
