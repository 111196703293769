import {CodeBlock} from '../../../../components/CodeBlock';

export const Section3Exercises = <>
  <div className='exercise'>
    <p>
      <strong>Exercise 1</strong><br />
      Change the value of the <code>todo1</code> variable to a number (e.g. 99) and run <code>typeof todo1</code>. What is the type?
    </p>
    <CodeBlock
      revealId='s3e1'
      language='javascript'
    >
{`let todo1 = 99;
console.log(typeof todo1);    // Result is: "number"`}
    </CodeBlock>
  </div>

  <div className='exercise'>
    <p>
      <strong>Exercise 2</strong><br />
      Create 3 variables: month, dayOfMonth, year. Use these variables to <code>console.log</code> today's date in one line in this format: <code>"November 9, 2021"</code>
    </p>
    <CodeBlock
      revealId='s3e2'
      language='javascript'
    >
{`let month = 'November';
let dayOfMonth = '9';
let year = '2021';
console.log(month + ' ' + dayOfMonth + ', ' + year);`}
    </CodeBlock>
  </div>

  <div className='exercise'>
    <p>
      <strong>Exercise 3</strong><br />
      Save the result of <code>4 + 5 * 3</code> in a variable and <code>console.log</code> the result. Do the same with the result of <code>(4 + 5) * 3</code>. Notice the rules of math are the same in programming (brackets first, then multiply, then add).
    </p>
    <CodeBlock
      revealId='s3e3'
      language='javascript'
    >
{`let result1 = 4 + 5 * 3;
console.log(result1);   // Result is: 19
let result2 = (4 + 5) * 3;
console.log(result2);   // Result is: 27`}
    </CodeBlock>
  </div>

  <div className='exercise'>
    <p>
      <strong>Exercise 4</strong><br />
      Create a variable <code>age</code> that saves your age (e.g. <code>let age = 25;</code>). Create another variable: <code>let message = 'I am ' + age + ' years old';</code> What is the type of the <code>message</code> variable?
    </p>
    <CodeBlock
      revealId='s3e4'
      language='javascript'
    >
{`let age = 25;
let message = 'I am ' + age + ' years old';
console.log(typeof message);    // Result is: "string".
                                // Combining a string and a number results in a string.`}
    </CodeBlock>
  </div>
</>;
