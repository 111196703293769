import {CodeBlock} from '../../../../components/CodeBlock';

export const Section6Exercises = <>
  <div className='exercise'>
    <p>
      <strong>Exercise 1</strong><br />
      Create a function named <code>greeting</code> that takes 1 parameter <code>firstName</code> and uses it to console.log a message saying "hello".
    </p>
    <p>
      Example: <code>greeting('Simon');</code> will <code>console.log('hello Simon')</code>.
    </p>
    <CodeBlock
      revealId='s6e1'
      language='javascript'
    >
{`function greeting(firstName) {
  console.log('hello ' + firstName);
}
greeting('Simon');`}
    </CodeBlock>
  </div>

  <div className='exercise'>
    <p>
      <strong>Exercise 2</strong><br />
      Write a function named <code>toUpper</code> that converts 1 string parameter <code>str</code> to uppercase and console.log the result. Use Google to find the code for converting a string to uppercase in Javascript.
    </p>
    <p>
      Example: <code>toUpper('Simon')</code>; will <code>console.log('SIMON')</code>
    </p>
    <CodeBlock
      revealId='s6e2'
      language='javascript'
    >
{`function toUpper(str) {
  console.log(str.toUpperCase());
}
toUpper('Simon');`}
    </CodeBlock>
  </div>

  <div className='exercise'>
    <p>
      <strong>Exercise 3</strong><br />
      Write a function that converts a parameter <code>length</code> from inches to centimeters.
    </p>
    <CodeBlock
      revealId='s6e3'
      language='javascript'
    >
{`function inchToCm(length) {
  console.log(length * 2.54);
}
inchToCm(10);`}
    </CodeBlock>
  </div>
</>;
