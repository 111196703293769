import {CodeBlock} from '../../../../components/CodeBlock';

export const Section7Exercises = <>
  <div className='exercise'>
    <p>
      <strong>Exercise 1</strong><br />
      Write a function <code>toUpper</code> that takes 1 parameter, an array of strings, and console.logs a new array with all the strings capitalized.
    </p>
    <p>(Hint: create a new array and push onto the array. Use google to learn the code for converting a string to uppercase in Javascript.</p>
    <p>Example: <code>toUpper(['hello', 'world']);</code> will <code>console.log(['HELLO', 'WORLD'])</code></p>
    <CodeBlock
      revealId='s7e1'
      language='javascript'
    >
{`function toUpper(stringArr) {
  let result = [];

  stringArr.forEach(function (str) {
    result.push(str.toUpperCase());
  });

  console.log(result);
}
toUpper(['hello', 'world']);`}
    </CodeBlock>
  </div>

  <div className='exercise'>
    <p>
      <strong>Exercise 2</strong><br />
      Write a function <code>arrayDouble</code> that takes an array and console.logs a new array with every value repeated twice.
    </p>
    <p>
      Example: <code>arrayDouble(['bark', 'meow']);</code> will <code>console.log(['bark', 'bark', 'meow', 'meow'])</code>
    </p>
    <CodeBlock
      revealId='s7e2'
      language='javascript'
    >
{`function arrayDouble(stringArr) {
  let result = [];

  stringArr.forEach(function (str) {
    result.push(str);
    result.push(str);
  });

  console.log(result);
}
arrayDouble(['bark', 'meow']);`}
    </CodeBlock>
  </div>

  <div className='exercise'>
    <p>
      <strong>Exercise 3</strong><br />
      Write a function <code>arraySum</code> that takes an array of numbers and console.logs the sum of the numbers. (Hint: create a variable and increase its value like this):
    </p>
    <CodeBlock
      language='javascript'
    >
{`let total = 0;
total = total + 10;`}
    </CodeBlock>

    <p className='mt-3'>
      Example: <code>arraySum([1, 2, 3]); = 6</code> and <code>arraySum([5, -2, 7, 0]); = 10</code>
    </p>
    <CodeBlock
      revealId='s7e3'
      language='javascript'
    >
{`function arraySum(numArray) {
  let total = 0;

  numArray.forEach(function (num) {
    total = total + num;
  });

  console.log(total);
}
arraySum([1, 2, 3]);
arraySum([5, -2, 7, 0]);`}
    </CodeBlock>
  </div>
</>;
