export function NestedLayoutsTechnique() {
  return <div className='NestedLayoutsTechnique container-xl'>
    <img src='/public/img/references/css/nested-layouts-technique/10a.png'
      alt='exercise 10a design'
      style={{
        width: 550,
        display: 'block',
        border: '1px solid rgb(200, 200, 200)',
        marginBottom: 50,
        marginTop: 30
      }} />
    <img src='/public/img/references/css/nested-layouts-technique/10b.png'
      alt='exercise 10b design'
      style={{
        width: 600,
        display: 'block',
        border: '1px solid rgb(200, 200, 200)',
        marginBottom: 50,
        marginTop: 30
      }} />
    <img src='/public/img/references/css/nested-layouts-technique/10c.png'
      alt='exercise 10c design'
      style={{
        width: 600,
        display: 'block',
        border: '1px solid rgb(200, 200, 200)',
        marginBottom: 50,
        marginTop: 30
      }} />
    <img src='/public/img/references/css/nested-layouts-technique/10d.png'
      alt='exercise 10d design'
      style={{
        width: 900,
        display: 'block',
        marginBottom: 50,
        marginTop: 30
      }} />
  </div>;
}
