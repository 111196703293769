import {Helmet} from 'react-helmet-async';
import {TwitterIcon} from '../icons/bootstrap-1.4.0/TwitterIcon';
import {YouTubeIcon} from '../icons/bootstrap-1.4.0/YouTubeIcon';

export function Contact() {
  return (
    <div className='Contact container-xl mt-5'>
      <Helmet defer={false}>
        <title>Contact | supersimple.dev</title>
        <meta
          name='description'
          content="I\'d love to hear from you! Here are the ways to contact me and stay up to date."
        />
      </Helmet>
      <div className='row'>
        <div className='col'>
          <h1 className='h2 fw-bold mb-3'>Contact</h1>
          <p className='mb-4'>
            I'd love to hear from you!<br />
            Here's how to contact me and stay up to date:
          </p>
          <div>
            <a href='https://youtube.com/c/supersimpledev' className='social-media-link youtube-link'
              target='_blank' rel='noreferrer'>
              <YouTubeIcon />
              YouTube channel
            </a>
          </div>
          <div>
            <a href='mailto:simon@supersimple.dev' className='social-media-link email-link'>
              <img src='/public/img/gmail-icon.svg' alt='email icon' />
              Email me
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
