import {CodeBlock} from '../../../../components/CodeBlock';

export const Section9Exercises = <>
  <div className='exercise'>
    <p>
      <strong>Exercise 1</strong><br />
      Create a shopping cart. Set up a <code>{`<div id="cart"></div>`}</code> and 4 buttons labeled "Apple", "Tomato", "Eggs", and "Clear". When clicking the first 3 buttons, add a div inside the <code>{`<div id="cart">`}</code> containing your food selection. When clicking "Clear" remove all items from the cart.
    </p>
    <p>Here’s what it looks like:</p>
    <video src='/public/courses/jsbeginner20201/s9e1.mov' muted controls style={{width: 280}}
      className='my-2'>
      <source src='/public/courses/jsbeginner20201/s9e1.mov' type='video/mp4' />
      Your browser does not support the video tag.
    </video>
    <CodeBlock
      revealId='s9e1'
      language='html'
      fileName='solution.html'
    >
{`<button onclick="addToCart('Apple')">Apple</button>
<button onclick="addToCart('Tomato')">Tomato</button>
<button onclick="addToCart('Eggs')">Eggs</button>
<button onclick="clearCart()">Clear</button>
<div id="cart"></div>
<script>
  // BONUS POINTS if you saved this variable outside the
  // function so you can reuse it and not have to run
  // document.getElementById() every time.
  const cart = document.getElementById('cart');

  // BONUS POINTS if you wrote this function with 1 parameter.
  // Writing separate functions like addApple(), addTomato(),
  // is fine too, but this make the function more reusable.
  function addToCart(food) {
    const cartItem = document.createElement('div');
    cartItem.innerText = food;
    cart.appendChild(cartItem);
  }

  function clearCart() {
    cart.innerHTML = '';
  }
</script>`}
    </CodeBlock>
  </div>

  <div className='exercise'>
    <p>
      <strong>Exercise 2</strong><br />
      Create a cm-inch converter. Create a textbox, 2 buttons: "Convert to cm" and "Convert to inch", and a <code>{`<div id="result"></div>`}</code>.
    </p>
    <p>When clicking the "Convert to cm" button, take the value in the texbox, convert it from a string to a number (hint: use + to convert a string to a number. Example: <code>+'10'</code>), then display the result in cm in the <code>{`<div id="result">`}</code>
    </p>
    <p>
      Examples:<br/>
      value in textbox = 1, Click "Convert to cm", <code>{`<div id="result">2.54 cm</div>`}</code><br/>
      value in textbox = 25.4, Click "Convert to in", <code>{`<div id="result">10 in</div>`}</code><br/>
    </p>
    <video src='/public/courses/jsbeginner20201/s9e2.mov' muted controls style={{width: 430}}
      className='my-2'>
      <source src='/public/courses/jsbeginner20201/s9e2.mov' type='video/mp4' />
      Your browser does not support the video tag.
    </video>
    <CodeBlock
      revealId='s9e2'
      language='html'
      fileName='solution.html'
    >
{`<input id="measurement" />
<button onclick="convertToCm()">Convert to cm</button>
<button onclick="convertToInch()">Convert to inch</button>
<script>
  const measurement = document.getElementById('measurement');

  function convertToCm() {
    const value = measurement.value;

    // value is a string and strings can't be multiplied. We need
    // to convert it into a number before we can multiply it.
    const convertedValue = +value * 2.54;

    const result = document.createElement('div');
    result.innerText = convertedValue;
    document.body.appendChild(result);
  }

  function convertToInch() {
    const value = measurement.value;
    const convertedValue = +value / 2.54;

    const result = document.createElement('div');
    result.innerText = convertedValue;
    document.body.appendChild(result);
  }
</script>`}
    </CodeBlock>
  </div>
</>;
