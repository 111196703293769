import {CodeBlock} from '../../../../components/CodeBlock';

export const Section7Code = <>
<CodeBlock
  language='html'
  fileName='todo.html'
  lineNumbers
  className='mt-0'
>{`<html>
  <head>
    <title>My Todo App</title>
  </head>
  <body>
    <button>Press me</button>
    <script>
      let todos = ['Get groceries', 'Wash car', 'Make dinner'];
      todos.push('another todo');

      todos.forEach(function (todoTitle) {
        let element = document.createElement('div');
        element.innerText = todoTitle;
        document.body.appendChild(element);
      });
    </script>
  </body>
</html>`}</CodeBlock>
</>;
