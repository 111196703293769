import {CodeBlock} from '../../../../components/CodeBlock';

export const Section11Exercises = <>
  <div className='exercise'>
    <p>
      <strong>Exercise 1</strong><br />
      There are 2 ways to access a value in an object:
    </p>
    <CodeBlock
      language='javascript'
    >
{`let todo = {
  name: 'Apple',
  price: 3
};

todo.price;       // First way
todo['price'];    // Second way

// The second way allows you to use a variable to access the value
let prop = 'price';
todo[prop];   // prop will be replaced by its value so this is the
              // same as todo['price']`}
    </CodeBlock>
    <p>
      Write a function createCart that takes an object representing prices of food.<br/>
      For example: <code>{`createCart({ Apple: 3, Orange: 4, Egg: 2 });`}</code>
    </p>
    <ol>
      <li>Create a variable: let total = 0;</li>
      <li>
        Run the code: <code>{`Object.keys({ ... });`}</code> using the object to get a list of its properties. Example: <code>{`Object.keys({ Apple: 3, Orange: 4, Egg: 2 });`}</code> results in <code>['Apple', 'Orange', 'Egg']</code></li>
      <li>For each food, render a div containing the name, price, and an "Add" button.</li>
      <li>When pressing the add button, add the price of the food to the total variable. Example: <code>{`<div>Apple $3<button ...>Add</button></div>`}</code>, Click button, <code>total = total + 3</code></li>
    </ol>

    <video src='/public/courses/jsbeginner20201/s11e1.mov' muted controls style={{width: 450}}
      className='my-2'>
      <source src='/public/courses/jsbeginner20201/s11e1.mov' type='video/mp4' />
      Your browser does not support the video tag.
    </video>
    <CodeBlock
      revealId='s11e1'
      language='javascript'
      className='mb-6'
    >
{`let total = 0;

function createCart(foodPrices) {
  const foods = Object.keys(foodPrices);

  foods.forEach(function (food) {
    const cartItem = document.createElement('div');

    // Here we get the food price using the food variable.
    // If food = 'Apple', then foodPrices[food] is the same
    // as foodPrices['Apple'], which is the same as foodPrices.Apple
    const foodPrice = foodPrices[food];
    cartItem.innerText = food + ' `}${`' + foodPrice;

    const addButton = document.createElement('button');
    addButton.innerText = 'Add';

    // Sorry I didn't teach this (I'll revise in the 2022 edition)!
    // You'll have to use a function without a name here. If you
    // create a separate addToCart() function, it will not have
    // access to any of the variables in this function.
    addButton.onclick = function () {
      total = total + foodPrice;
    };

    cartItem.appendChild(addButton);

    // This wil render each food with an "Add" button onto the webpage.
    document.body.appendChild(cartItem);
  });
}

createCart({ Apple: 3, Orange: 4, Egg: 2 });`}
    </CodeBlock>
  </div>
</>;
