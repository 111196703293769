export function DNSRecords() {
  return <div className='NestedLayoutsTechnique container-xl'>
    <div className='row'>
      <div className='col-6 offset-3'>
        <h1 className='h3'>
          DNS Records
        </h1>
        <p>How to set up DNS records for your domain registrar:</p>
        <p className='mb-5'>
          <a href='https://www.youtube.com/watch?v=p1QU3kLFPdg&t=1158s'
            target='_blank' rel='noreferrer'>
            Namecheap
          </a><br />
          <a href='https://www.domain.com/help/article/dns-management-how-to-update-dns-records'
            target='_blank' rel='noreferrer'>
            Domain.com
          </a><br />
          <a href='https://www.bluehost.com/help/article/dns-management-add-edit-or-delete-dns-entries#add'
            target='_blank' rel='noreferrer'>
            Bluehost
          </a><br />
          <a href='https://help.dreamhost.com/hc/en-us/articles/360035516812-Adding-custom-DNS-records-'
            target='_blank' rel='noreferrer'>
            DreamHost
          </a><br />
          <a href='https://docs.gandi.net/en/domain_names/common_operations/dns_records.html#editing-your-dns-records'
            target='_blank' rel='noreferrer'>
            Gandi
          </a><br />
          <a href='https://godaddy.com/help/add-an-a-record-19238'
            target='_blank' rel='noreferrer'>
            GoDaddy
          </a><br />
          <a href='https://support.google.com/domains/answer/3290350?hl=en#zippy=%2Cadd-a-resource-record'
            target='_blank' rel='noreferrer'>
            Google Domains
          </a><br />
          <a href='https://www.hostgator.com/help/article/how-to-change-dns-zones-mx-cname-and-a-records#add-dns'
            target='_blank' rel='noreferrer'>
            HostGator
          </a><br />
          <a href='https://support.hostinger.com/en/articles/1583249-how-to-manage-my-dns-records-at-hostinger'
            target='_blank' rel='noreferrer'>
            Hostinger
          </a><br />
          <a href='https://docs.aws.amazon.com/Route53/latest/DeveloperGuide/migrate-dns-domain-in-use.html'
            target='_blank' rel='noreferrer'>
            Route53 (AWS)
          </a><br />
        </p>
        <p>
          <strong>Don't see your domain registrar?</strong><br />
          Let me know <a href='https://www.youtube.com/watch?v=p1QU3kLFPdg&t=1131s' target='_blank' rel='noreferrer'>in the comments</a> which domain registrar you want added. You can also try searching "manage DNS records [your_domain_registrar]" in Google.
        </p>
      </div>
    </div>
  </div>;
}
