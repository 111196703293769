import {Link, useHistory} from 'react-router-dom';
import {ClipBoardCheckIcon} from '../../icons/bootstrap-1.4.0/ClipBoardCheckIcon';
import {ClockIcon} from '../../icons/bootstrap-1.4.0/ClockIcon';
import {PlayButtonIcon} from '../../icons/bootstrap-1.4.0/PlayButtonIcon';

interface Props {
  image: {
    src: string;
    alt: string;
  };
  title: string;
  description: string;
  time: string;
  lessons: string;
  exercises?: string;
  courseLink: string;
  certificateLink?: string;
}

export function CourseCard({
  image, title, description, time, lessons, exercises,
  courseLink, certificateLink
}: Props) {
  const history = useHistory();

  const clickCard = () => {
    const selection = window.getSelection()?.toString();
    if (!selection) history.push(courseLink);
  };

  return (
    <div className='CourseCardContainer'>
      <div className='CourseCard card pt-3 mb-1' onClick={clickCard}>
        <div className='CourseCard__image px-3'>
          <img src={image.src} alt={image.alt} />
        </div>
        <div className='card-body'>
          <div className='CourseCard__title'>{title}</div>
          <div className='CourseCard__description'>{description}</div>

          <div className='CourseCard__info'>
            <div>
              <div className='CourseCard__time'>
                <span className='CourseCard__time-icon'>
                  <ClockIcon />
                </span>
                {time}
              </div>
              <div className='CourseCard__lessons'>
                <span className='CourseCard__lessons-icon'>
                  <PlayButtonIcon />
                </span>
                {lessons}
              </div>
              {exercises && (
                <div className='CourseCard__exercises'>
                  <span className='CourseCard__exercises-icon'>
                    <ClipBoardCheckIcon />
                  </span>
                  {exercises}
                </div>
              )}
            </div>

            <div>
              <Link to={courseLink} className='CourseCard__start btn btn-sm btn-primary'>
                Start
              </Link>
            </div>
          </div>

        </div>
      </div>
      {certificateLink &&
        <div className='CourseCard__certificate px-3'>
          <span className='available-message'>
            Certificate available!
          </span>
          <a href={certificateLink} className='btn btn-primary'>
            Enroll Now
          </a>
        </div>
      }
    </div>
  );
}
